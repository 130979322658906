export default function setCategoryAction(category) {
    if(category) {
        return {
            type: 'SET_CATEGORY',
            payload: category,
        }
    } else {
        return {
            type: 'SET_CATEGORY',
            payload: false,
        }
    }

}
