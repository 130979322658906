import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import {NavLink} from 'react-router-dom';
import {withStyles} from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import HomeIcon from '@material-ui/icons/Home';
import './Breadcrumbs.scss';
import {connect} from "react-redux";


const styles = theme => ({
    root: {
        justifyContent: 'center',
        flexWrap: 'wrap',
    },
    paper: {
        padding: theme.spacing(1, 2),
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
    link: {
        display: 'flex',
    },
});

class BreadcrumbsItem extends Component {

    render() {

        return (
            <NavLink exact className="breadcrumbs__link" activeClassName='active'
                     to={this.props.path}> {this.props.name}</NavLink>
        );
    }
}

class SimpleBreadcrumbs extends Component {

    renderCurrentTarget(){
        const {category, product, article} = this.props;

        let current = category || product || article || '';

        if(!current) {
            return ''
        } else {
            return(
                <div color="inherit" >
                   {current.toUpperCase()}
                </div>
            )
        }

    }

    render() {
        const {classes,menu} = this.props;
        if(!menu) {
            return ''
        }
        return (
            <div className="container breadcrumbs container__breadcrumbs">
                <div className={classes.root}>
                    <Paper elevation={0} className={classes.paper}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link color="inherit" href="/" className={classes.link}>
                                <HomeIcon className={classes.icon}/>
                                ГЛАВНАЯ
                            </Link>
                            <Switch>
                                <Route exact path="/catalog.html" render={(props) => <BreadcrumbsItem {...props}  name='КАТАЛОГ' path={"/catalog.html"}/>}/>
                                <Route exact path="/catalog/:type" render={(props) => <BreadcrumbsItem {...props}  name='КАТАЛОГ' path={"/catalog.html"}/>}/>
                                <Route exact path="/products.html" render={(props) => <BreadcrumbsItem {...props}  name='КАТАЛОГ' path={"/catalog.html"}/>}/>
                                <Route exact path="/products/:id" render={(props) => <BreadcrumbsItem {...props}  name='КАТАЛОГ' path={"/catalog.html"}/>}/>
                                <Route exact path="/articles.html" render={(props) => <BreadcrumbsItem {...props}  name='СТАТЬИ' path={"/articles.html"}/>}/>
                                <Route path="/articles/:article" render={(props) => <BreadcrumbsItem {...props}  name='СТАТЬИ' path={"/articles.html"}/>}/>
                                <Route exact path="/contact.html" render={(props) => <BreadcrumbsItem {...props}  name='КОНТАКТЫ' path={"/contact.html"}/>}/>
                                <Route path="/gallery.html"  render={(props) => <BreadcrumbsItem {...props}  name='ГАЛЕРЕЯ' path={"/gallery.html"}/>}/>
                                <Route path="/reviews.html" render={(props) => <BreadcrumbsItem {...props}  name='ОТЗЫВЫ' path={"/reviews.html"}/>}/>
                                <Route path="/optovikam.html" render={(props) => <BreadcrumbsItem {...props}  name='Памятники оптом в Крыму' path={"/optovikam.html"}/>}/>
                                <Route path="/ustanovka.html" render={(props) => <BreadcrumbsItem {...props}  name='Установка памятника' path={"/ustanovka.html"}/>}/>
                            </Switch>
                            {this.renderCurrentTarget()}
                        </Breadcrumbs>
                    </Paper>
                </div>
            </div>
        );
    }
};

function mapStateToProps(state) {

    return {
        menu: state.interafaceData.menu,
        category: state.interafaceData.category,
        product: state.interafaceData.product,
        article: state.interafaceData.article,
    }
}

export default connect(mapStateToProps)( withStyles(styles)(SimpleBreadcrumbs));
