import React, {Component} from 'react';
import './MiniSlider.scss';

class MiniSlider extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeSlide: 0,
            interval: 3,
            autoplay: true,
            slides: [
                {
                    background: "/assets/banner-1.png",
                },
                {
                    background: "/assets/banner-2.png",
                },
            ]
        };
    }

    componentDidMount() {
        this.timerId = setInterval(() => {
            if (this.state.autoplay) {
                this.nextSlide();
            }
        }, this.state.interval * 1000);
    }

    componentWillUnmount() {
        clearInterval(this.timerId);
    }


    nextSlide() {
        let slide = this.state.activeSlide + 1 < this.state.slides.length
            ? this.state.activeSlide + 1
            : 0;
        this.setState({
            activeSlide: slide
        });
    }

    renderSlides() {

        return this.state.slides.map((slide, index) => {
            return (
                <div
                    key={index}
                    className="mini-slider__slide"
                    data-active={index === this.state.activeSlide}
                    style={{'backgroundImage': `url(${slide.background})`}}

                >
                </div>

            );
        });
    }

    render() {

        return (

            <div className="mini-slider">
                <div className="mini-slider__container">
                {this.renderSlides()}
                </div>
            </div>

        );
    }
}


export default MiniSlider;