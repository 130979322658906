import React from 'react';
import TitleBar from '../Menu/TitleBar';
import DrawerMenu from '../Menu/DrawerMenu';
import Banner from '../Banner/Banner';
import Advantages from '../Advantages/Advantages';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import './Header.scss';
import {Route, Switch,} from 'react-router-dom';


const Header = () => {
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen)
    };

    const handleDrawerClose = () => {
        setMobileOpen(false)
    };

    return (
        <React.Fragment>
            <TitleBar  handleDrawerToggle={handleDrawerToggle} />
            <DrawerMenu mobileOpen={mobileOpen} handleDrawerClose={handleDrawerClose} />
            <Switch>
                <Route exact path='/' component={Banner} />
            </Switch>
            <Advantages />
            <Breadcrumbs />
        </React.Fragment>
    )
};

export default Header