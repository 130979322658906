import React, {Component} from 'react';
import ym from 'react-yandex-metrika';
import ReactGA from 'react-ga';
// import Communication from '../../Communication';
import './Page404.scss';
import Button from "../Button/Button";
import Feedback from "../Feedback/Feedback";
import {Helmet} from 'react-helmet';
class Page404 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            promoData: [],
            openModal: false,
        };
        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidMount() {
        // Communication.fetchMenuData().then((result) => {
        //   this.setState({ menuData: result })
        // })

    }
    handleClickOpen() {

        if(process.env.REACT_APP_GOOGLE==='active') {
            ReactGA.event({
                category: 'klik_po_knopke',
                action: 'obratny_zvonok',
                label: 'main_banner'
            });
            ym('reachGoal','obratny_zvonok_main_banner');
        }
        this.setState({
            openModal: true
        })


    }

    handleClose() {
        this.setState({
            openModal: false
        })
    }


    render() {
        return (<>
            <Helmet>
                <title>Страница не найдера, ошибка 404,  "Памятники в Чистеньком".</title>
                <meta name="description"
                      content="Памятники, надгробия, надгробные камни - заказ и установка в городах Крыма: Симферополь, Севастополь, Ялта, Алушта, Евпатория."/>
                <meta name="keywords"
                      content="Памятники,надгробия,надгробные камни"/>
            </Helmet>
            <div className="page404">
                <div className="top_title"><h1> Страница не найдена <br/>(ОШИБКА 404)</h1></div>
                <div className="page404__container">
                    <div className="page404__item">


                        <Button  className="button__banner-callback"
                                 type='callback'
                                 name="Сообщить о проблеме"
                                 handleClick={this.handleClickOpen.bind(this)}/>

                    </div>
                </div>
                <Feedback name="Сообщить о проблеме" handleClose={this.handleClose} open={this.state.openModal}/>
            </div>
        </>);

    }
}

export default Page404

