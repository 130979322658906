import React, {Component} from 'react';
import './Catalog.scss';
import Button from '../Button/Button';
import {Link, NavLink} from 'react-router-dom'
import {Helmet} from 'react-helmet';
import {connect} from 'react-redux';
// import WhyWe from '../WhyWe/WhyWe';
import Communication from '../../Communication';
import setCategoryAction from "../../actions/actionChangeCategory";

class Catalog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            homepage: false,
            articles: '',
            initialCategory: '',
            currentCategory: '',
            subCategories: ''
        };
        this.selectProductItem = this.selectProductItem.bind(this);
        this.changeCategory = this.changeCategory.bind(this);

        this._products = {};
    }

    async componentDidMount() {
        window.scrollTo(0, 0);

       /* Communication.fetchArticlesData().then((result) => {
            if (!result) {
                return
            }
            let sortResult = result.sort((a, b) => {
                return a.sortindex - b.sortindex
            });
            this.setState({articles: sortResult})
        });*/

        const result = await Communication.fetchArticlesData();

        // let sortResult = result;/*.sort((a, b) => {
        //     return a.sortindex - b.sortindex
        // });*/
        this.setState({articles: result});
        if (window.location.pathname === '/') {
            this.setState({homepage: true})
        }
        const {menu} = this.props;

        if (!menu) {
            return
        }

        const sub = this.props.menu.find((v) => {
            return v.subcategories.length > 0
        });
        // const url = this.getUrlParams();
        this.setState({subCategories: sub.subcategories})
        // this.setState({menudata: sub})

    }

    /*componentWillReceiveProps() {

        const {menu} = this.props;

        if (!menu) {
            return
        }

        const sub = this.props.menu.find((v) => {
            return v.subcategories.length > 0
        });
        console.warn('..sub:',sub);

        this.setState({subCategories: sub.subcategories})
        this.setState({menudata: sub})
    }*/

    getUrlParams() {
        let str = window.location.pathname.replace(/(.html)/g, "");
        let arr = str.split('/');
        let field = arr.splice(-1, 1);

        return field[0]
    }

    getData(category) {
        let currentCategory = category;
        let result;
        let url = this.getUrlParams();

        const {articles, subCategories} = this.state;

        if (!articles) {
            return ''
        }
        if (url === 'catalog') {

            result = articles;
            return result
        }

        if (!currentCategory) {
            if (!subCategories) {
                return ''
            }

            let urlCategory = this.state.subCategories.find((item) => {
                return item.url === url
            });
            const data = urlCategory;
            currentCategory = data?.name||'';
            this.props.setCatFunction(currentCategory);
            result =  this.getData(currentCategory);
        } else {

            this._products[currentCategory] = articles.filter((item) => {
                return item.types.includes(currentCategory);
            });
            result = this._products[currentCategory]
        }
/*
        if (!this._products[currentCategory]) {
            this._products[currentCategory] = articles.filter((item) => {
                return item.types.includes(currentCategory);
            });

            result = this._products[currentCategory]
        }*/

        return result

    }

    selectProductItem() {
        this.props.setCatFunction(false);
        // console.log('event click перейти на страницу товара');

    }

    changeCategory(name) {
        this.props.setCatFunction(name);
    }

    renderSubCategories() {
        if (!this.state.subCategories) {
            return ''
        }
        return this.state.subCategories.map((subItem, index) => {

            return (
                <NavLink key={index} className='sidebar__list-subitem'
                         to={`/catalog/${subItem.url}.html`}
                         onClick={this.changeCategory.bind(this, subItem.name)}
                >
                    {subItem.icon ? <img className='sidebar__subitem-icon' src={`/assets/${subItem.icon}`} alt="cat icon"/> : ''}
                    {subItem.name}</NavLink>
            )
        })
    }

    renderItems() {
        let currentProducts;

        if (this.state.homepage /*&& !this.props.category*/) {

            currentProducts = this.state.articles;

        } else if (!this.props.category) {
            currentProducts = this.getData(this.props.category);
        } else {
            currentProducts = this.getData(this.props.category);
        }

        if (!currentProducts) {
            return ''
        }

        return currentProducts.map((item, index) => {
            // TODO: универсальная картинка
            return (
                <div key={index} className="catalog__item" onClick={this.selectProductItem}>
                    <Link className="product-item" to={`/products/${item.url}.html`}>
                        <div className="product-item__title"><span>{item.name}</span></div>
                        <div className="product-item__image">
                            <img
                                src={`/assets/articles/${item.photos.length > 0 ? item.photos[0] : '0ad787c2c73bdd15140bb2b05270e5f9'}.jpg`}
                                alt={`${item.name}`}/>
                        </div>
                        <div className="product-item__desc">
                            <span className="product-item__name">Стела</span>
                            <span className="product-item__params">{item.variants.length > 0 ? item.variants[0].stele : ''}</span>

                        </div>
                        <div className="product-item__price-block">
                            <div className="product-item__price">{`от ${item.variants.length ? item.variants[0].price : '0'} руб.`} </div>
                            <Button className="product-item__button button_outline" type='catalog' name="Подробнее"/>
                        </div>
                    </Link>
                </div>
            )
        })

    }


    render() {
        const {homepage } = this.state;
        const url = this.getUrlParams();
        let _title='Изготовление памятников';
        let _description='Изготовление памятников - от 11.350 руб. за комплект. Доставка и установка по Крыму. Гарантия 25 лет. Более 180 моделей памятников на могилу.';
        let _h1='Изготовление памятников';
        let _h2=<>
            <p>Мы занимаемся изготовлением памятников из гранита более 12 лет. Огромный опыт позволяет нам качественно выполнять самые сложные заказы. Вы можете купить памятник из гранита, выбрав на сайте понравившуюся вам модель. Если же из 180 представленных на сайте моделей вам ничего не понравилось, то мы можем изготовить памятник по индивидуальному заказу. На все изготавливаемые нами памятники, мы даём гарантию 25 лет.</p>
            <p>Среди видов памятников, которые вы можете купить, у нас есть разные варианты:</p>
            <ul>
                <li >Простые памятники</li>
                <li >Двойные памятники</li>
                <li>Памятники с крестом</li>
                <li>Кресты</li>
                <li>Мусульманские памятники </li>
                <li>Памятники по индивидуальному заказу</li>
            </ul>
            <p>В производстве памятников мы используем только натуральный камень:</p>
            <ul>
                <li>Гранит Габбро</li>
                <li>Лезниковский гранит</li>
            </ul>
            <p>Помимо изготовления памятников, у нас вы можете заказать полный комплекс услуг по благоустройству могил в Симферополе и по всему Крыму</p>
            <p>Звоните и наши менеджеры помогут вам решить любой вопрос по благоустройству могил.</p>
        </>
        if(url==='catalog')
        {
            _title ='Памятники на могилу';
            _description='Заказать памятник на могилу вы можете на сайте и по телефону +7 (978) 789-04-52 . Доставка и установка по Крыму. Гарантия 25 лет.';
            _h1='Памятники на могилу';


        }else if(url==='simple'){
            _title ='Недорогие памятники';
            _description='Заказать недорогой памятник из гранита вы можете на сайте и по телефону +7 (978) 789-04-52 . Гарантия 25 лет. Доставка и установка по Крыму.' ;
            _h1='Недорогие памятники';
            _h2=<>
            <p>Мы занимаемся изготовлением недорогих памятников из гранита более 12 лет. Огромный опыт позволяет нам качественно выполнять самые сложные заказы. Вы можете купить недорогой памятник из гранита, выбрав на сайте понравившуюся вам модель. Если же из представленных на сайте моделей вам ничего не понравилось, то мы можем изготовить памятник по индивидуальному заказу. На все изготавливаемые нами памятники распространяется гарантия 25 лет.
            </p>
            <p>В производстве памятников мы используем только натуральный камень:</p>
            <ul>
                <li>Гранит Габбро</li>
                <li>Лезниковский гранит</li>
            </ul>
            <p>Помимо изготовления памятников, у нас вы можете заказать полный комплекс услуг по благоустройству могил в Симферополе и по всему Крыму.</p>
            <p>Звоните и наши менеджеры помогут вам решить любой вопрос по благоустройству могил.</p>
            </>

        }else if(url==='roods'){
            _title ='Памятники с крестом';
            _description='Заказать памятник с крестом на могилу вы можете на сайте и по телефону +7 (978) 789-04-52 . Гарантия 25 лет. Доставка и установка по Крыму.';
            _h1='Памятники с крестом';
            _h2=<><p>Мы занимаемся изготовлением памятников с крестом из гранита более 12 лет. Огромный опыт позволяет нам качественно выполнять самые сложные заказы. Вы можете купить памятник с крестом из гранита, выбрав на сайте понравившуюся вам модель. Если же из представленных на сайте моделей вам ничего не понравилось, то мы можем изготовить памятник по индивидуальному заказу. На все изготавливаемые нами памятники распространяется гарантия 25 лет.</p>
                <p>В производстве памятников мы используем только натуральный камень:</p>
                <ul>
                    <li>Гранит Габбро</li>
                    <li>Лезниковский гранит</li>
                </ul>
                <p>Помимо изготовления памятников, у нас вы можете заказать полный комплекс услуг по благоустройству могил в Симферополе и по всему Крыму.</p>
            </>

        }else if(url==='islam'){
            _title ='Мусульманские памятники';
            _description='Заказать мусульманский памятник на могилу вы можете на сайте и по телефону +7 (978) 789-04-52 . Гарантия 25 лет. Доставка и установка по Крыму.';
            _h1='Мусульманские памятники';
            _h2=<>
                <p>Мы занимаемся изготовлением мусульманских памятников из гранита более 12 лет. Огромный опыт позволяет нам качественно выполнять самые сложные заказы. Вы можете купить мусульманский памятник из гранита, выбрав на сайте понравившуюся вам модель. Если же из представленных на сайте моделей вам ничего не понравилось, то мы можем изготовить памятник по индивидуальному заказу. На все изготавливаемые нами памятники распространяется гарантия 25 лет.</p>
                <p>В производстве памятников мы используем только натуральный камень:</p>
                <ul>
                    <li>Гранит Габбро</li>
                    <li>Лезниковский гранит</li>
                </ul>
                <p>Помимо изготовления памятников, у нас вы можете заказать полный комплекс услуг по благоустройству могил в Симферополе и по всему Крыму.</p>
            </>

                }else if (url==='double'){
            _title ='Двойные памятники';
            _description='Заказать двойной памятник на могилу вы можете на сайте и по телефону +7 (978) 789-04-52 . Гарантия 25 лет. Доставка и установка по Крыму.';
            _h1='Двойные памятники';
            _h2=<>
                <p>Мы занимаемся изготовлением двойных памятников из гранита более 12 лет. Огромный опыт позволяет нам качественно выполнять самые сложные заказы. Вы можете купить двойной памятник из гранита, выбрав на сайте понравившуюся вам модель. Если же из представленных на сайте моделей вам ничего не понравилось, то мы можем изготовить памятник по индивидуальному заказу. На все изготавливаемые нами памятники распространяется гарантия 25 лет.</p>
                <p>В производстве памятников мы используем только натуральный камень:</p>
                <ul>
                    <li>Гранит Габбро</li>
                    <li>Лезниковский гранит</li>
                </ul>
                <p>Помимо изготовления памятников, у нас вы можете заказать полный комплекс услуг по благоустройству могил в Симферополе и по всему Крыму.</p>
                <p>Звоните и наши менеджеры помогут вам решить любой вопрос по благоустройству могил.</p>
                </>
        }

        return (
            <>
                <Helmet><title>{`${_title}`} </title><meta name="description"
                          content={_description}/><meta name="keywords"
                          content="Памятники,надгробия,надгробные камни"/></Helmet>
                <div className="catalog">

                    {homepage ?
                       <div className="top_title" >  <h1 className="top_title">{_h1} </h1></div>
                        :
                        <div><h1 className="top_title" > {_h1} </h1></div>}

                    <div className="catalog__subcategories">{this.renderSubCategories()}</div>

                    <div className="catalog__container">{this.renderItems()}</div>
                    <div><hr/></div>
                    <div><h2 className="catalog__desc"> {_h2} </h2></div>
                    {homepage ?
                        <Link to="/catalog.html" className="catalog__link">
                            <Button className="catalog__button" type='catalog' name="Посмотреть все товары"/>
                        </Link>
                        :
                        <div className="top_title">Также возможно изготовление памятника любой формы по Вашим предпочтениям и пожеланиям.</div>

                    }

                </div>


            </>

        )
    }

}

function mapDispatchToProps(dispatch) {
    return {
        setCatFunction: (category) => {
            dispatch(setCategoryAction(category))
        },
    }
}

function mapStateToProps(state) {

    return {
        menu: state.interafaceData.menu,
        category: state.interafaceData.category,
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Catalog);

