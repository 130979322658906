import React, {Component} from 'react';
import './OurComand.scss';

// import Communication from '../../Communication';

class OurComand extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
        };
    }

    componentDidMount() {
        // Communication.fetchCategoriesData().then((result) => {
        //     this.setState({ catalogData: result })
        // })
        //
        // if (window.location.pathname === '/') {
        //     this.setState({ catalogPage: false })
        // }

        this.setState({
            data: [
                {
                    name: 'Инна',
                    position: 'Менеджер',
                    img: './assets/inna.jpg',
                    text: '“Стараюсь помочь при работе с каждым клиентом.”'
                },
                {
                    name: 'Владислав',
                    position: 'Руководитель',
                    img: './assets/vladislav.jpg',
                    text: '“Мы работаем, вкладывая душу, а результатами гордимся”!'
                },
                {
                    name: 'Алексей',
                    position: 'Художник по камню',
                    img: './assets/alex.jpg',
                    text: '“В портрете стараюсь показать человека, а не копировать изображение.”'
                },
                {
                    name: '',
                    position: '',
                    img: './assets/black_logo.png',
                    text: ''
                },
                {
                    name: 'Нариман',
                    position: 'Художник по камню',
                    img: './assets/nariman.jpg',
                    text: '“Портрет - это икусство, а искусство это то, чем я живу каждый день.”'
                },
                {
                    name: '',
                    position: '',
                    img: './assets/black_logo.png',
                    text: ''
                },
            ]
        })
    }

    renderItems() {
        return this.state.data.map((item, index) => {
            return (
                <div key={index} className="our-command__item">
                    <div className="our-command__image">
                        <img src={item.img} alt="commandimage" />
                    </div>
                    {item.name ? <div className="our-command__name">{item.name}</div> : ''}

                    {item.position ? <div className="our-command__position">{item.position}</div> : ''}
                    {item.text ? <p className="our-command__text">{item.text}</p> : ''}

                </div>
            )
        })
    }

    render() {

        return (
            <div className="our-command">
                <div className="top_title">наша команда</div>
                <div className="our-command__container">
                    {this.renderItems()}
                </div>
            </div>

        )
    }
}

export default OurComand;
