import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import {withStyles} from '@material-ui/styles';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Communication from "../../Communication";
import ReactGA from 'react-ga';
import ym from 'react-yandex-metrika';
const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        height:'100%'
    },

    root: {
        margin: 0,
        padding: theme.spacing(2),

    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },

    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    close: {
        backgroundColor: '#f33',
        borderRadius: '0',
        color: '#fff',
        "&:hover": {
            backgroundColor: "#000"
        }
    },
    actions: {
        display: 'flex',
        justifyContent: 'space-evenly',
        padding: '17px 0'
    },
    title: {
        marginTop: '30px',
        padding: '0 25px',
    },
    shipped: {
        marginTop: '15px',
        color: '#f33'
    },
    reviewField: {
        width:'400px',
        marginTop: '15px',
    },
    variant: {
        marginTop: '15px',
    }
});

class Feedback extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shipped: false,
            feedbackStatus: '',
            comment: ''
        };
        this.handleChange = this.handleChange.bind(this);
        this.sendFeedback = this.sendFeedback.bind(this);
        this.closeWindow = this.closeWindow.bind(this);
    }

    handleChange(name, e) {
        this.setState({
            [name]: e.target.value
        });
    }

    sendFeedback() {

        const {phone, contact, comment} = this.state;
        const {selectedProduct} = this.props;
        let content;
        /*ReactGA.event({
            category: 'Feedback',
            action: 'Feedback add'
        });*/
        if(process.env.REACT_APP_GOOGLE==='active') {
            ym('reachGoal','zayavka_product_page')
            ReactGA.event({
                category: 'otpravka_formy',
                action: 'zayavka',
                label: 'feedback'
            });
        }
        content = selectedProduct || comment;

        Communication.fetchNewRelation(contact, phone, content).then((result) => {

            if (result === 'success') {
                this.setState({
                    feedbackStatus: 'ВАША ЗАЯВКА ПРИНЯТА!'
                })
            } else {
                this.setState({
                    feedbackStatus: 'ЧТО ТО ПОШЛО НЕ ТАК :('
                })
            }

            this.setState({
                shipped: true
            })

        }).then(() => {
            setTimeout(this.closeWindow, 2000);
        });

    }

    closeWindow() {
        this.props.handleClose()
    }

    render() {
        const {classes,selectedProduct} = this.props;

        return (
            <div>

                <Dialog open={this.props.open} onClose={this.props.handleClose} aria-labelledby="form-dialog-title"  scroll='body'>
                    <MuiDialogTitle disableTypography className={classes.root}>
                        <Typography className={classes.title} variant="h5">{this.props.name}</Typography>

                        <IconButton aria-label="close" className={classes.closeButton} onClick={this.props.handleClose}>
                            <CloseIcon/>
                        </IconButton>

                    </MuiDialogTitle>

                    <DialogContent>
                        <form className={classes.container} noValidate autoComplete="off">
                            <TextField
                                error={this.state.contact ? false : true}
                                id="outlined-name"
                                label="Введите имя"
                                className={classes.textField}
                                onChange={this.handleChange.bind(this, 'contact')}
                                margin="normal"
                            />

                            <TextField
                                error={this.state.phone ? false : true}
                                id="outlined-number"
                                label="Номер телефона"
                                onChange={this.handleChange.bind(this, 'phone')}
                                type="number"
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                margin="normal"
                            />
                            {this.props.type ===  'reviews'?

                                <TextField
                                    className={classes.reviewField}
                                    variant="outlined"
                                    label="Введите Ваше отзыв"
                                    value={this.state.comment}
                                    onChange={this.handleChange.bind(this, 'comment')}
                                    placeholder="Введите Ваше отзыв"
                                    multiline={true}
                                    rows={5}
                                />

                                : ''}
                            {this.props.type ===  'opt'?

                                <TextField
                                    className={classes.reviewField}
                                    variant="outlined"
                                    label="Введите Ваше предложение "
                                    value={this.state.comment}
                                    onChange={this.handleChange.bind(this, 'comment')}
                                    placeholder="Введите Ваше предложение"
                                    multiline={true}
                                    rows={5}
                                />

                                : ''}
                        </form>

                        {selectedProduct ? <div className={classes.variant}>{`Вы выбрали: ${selectedProduct}`}</div>: ''}

                        {this.state.shipped ? <Typography variant="h6" className={classes.shipped}>
                            {this.state.feedbackStatus}
                        </Typography> : ''}

                    </DialogContent>
                    <DialogActions className={classes.actions}>
                       {/* <Button onClick={this.props.handleClose}>
                            ОТМЕНИТЬ
                        </Button>*/}
                        <Button onClick={this.sendFeedback}  disabled={this.state.phone && this.state.contact? false: true} className={classes.close}>
                            ОТПРАВИТЬ
                        </Button>

                    </DialogActions>

                </Dialog>
            </div>
        );
    }

}

export default withStyles(styles)(Feedback)

