import React, { Component } from 'react';
import './ScrollToTop.scss';

class ScrollToTop extends Component {
    constructor(props) {
        super(props);

        this.state = {
            intervalId: 0,
            show: false
        };
        this.scrollStep = this.scrollStep.bind(this);
        this.scrollToTop = this.scrollToTop.bind(this);
        this.onScroll = this.onScroll.bind(this);
    }
    UNSAFE_componentWillMount(){
        document.addEventListener('scroll', this.onScroll);
    }

    componentWillUnmount(){
        document.removeEventListener(this.onScroll);
    }

    onScroll() {
        let visible = window.scrollY >= 200;

        if (this.state.visible !== visible) {
            this.setState({
                visible: visible
            });
        }
    }

    scrollStep() {

        if (window.pageYOffset === 0) {
            clearInterval(this.state.intervalId);
        }
        window.scroll(0, window.pageYOffset - 50);
    }
    scrollToTop() {

        let intervalId = setInterval(this.scrollStep.bind(this), '10');
        this.setState({ intervalId: intervalId });
    }

    render() {
        return (<div className='scroll-to-top' style={{display: this.state.visible ? 'flex' : 'none' }} onClick={this.scrollToTop}>
                <img className='scroll-to-top__icon' src='/assets/arrow-up.svg' alt='icon' />
            </div>);
    }
}

export default ScrollToTop;

