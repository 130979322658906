import React, {Component} from 'react';
import './HomePage.scss';
import WhyWe from '../WhyWe/WhyWe';
import OrderSteps from '../OrderSteps/OrderSteps';
import OurComand from '../OurComand/OurComand';
import HomeGallery from '../HomeGallery/HomeGallery';
import HomeContacts from '../HomeContacts/HomeContacts';
import Catalog from '../Catalog/Catalog';
import Video from '../Video/Video';
import Remote from '../Remote/Remote';
// import {Helmet} from "react-helmet";

// import Communication from '../../Communication';

class HomePage extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        // Communication.fetchCategoriesData().then((result) => {
        //     this.setState({ catalogData: result })
        // })
        //

    }

    /**
     *  <Helmet>
     <title>Оптовая и розничная продажа памятников и надгробий в Симферополе, Симферопольском районе и по
     Крыму.</title>
     <meta name="description"
     content="Памятники в Чистеньком - изготовление памятников, надгробий, надгробных плит. Оформление и благоустройство могил. Оптовая и розничная продажа надгробных памятников на могилу в Симферополе."/>
     <meta name="keywords"
     content="памятники, заказать памятник, изготовление памятников, надгробные памятники, памятники из гранита, надгробие, памятник на могилу"/>
     </Helmet>
     * @returns {*}
     */
    render() {

        return (
            <>


                <div className='content'>
                    <Catalog/>
                    <OrderSteps/>
                    <WhyWe/>
                    <Remote/>
                    <HomeGallery/>
                    <Video/>
                    <OurComand/>
                    <HomeContacts/>
                </div>

            </>
        )
    }
}

export default HomePage;
