import React, {Component} from 'react';
import './HomeContacts.scss';
// import Communication from '../../Communication';

class HomeContacts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],

        };
    }

    componentDidMount() {
        // Communication.fetchCategoriesData().then((result) => {
        //     this.setState({ catalogData: result })
        // })
        //
        // if (window.location.pathname === '/') {
        //     this.setState({ catalogPage: false })
        // }

        this.setState({
            data: [
                {
                    type: 'phone',
                    icon: './assets/phone.png',
                    title: 'Наш контактный телефон',
                    subtitle: '',
                    text: '',
                    content: '+7 (978) 789-04-52'

                },
                {
                    type: 'address',
                    icon: './assets/address.png',
                    title: 'Адрес',
                    subtitle: '"ПАМЯТНИКИ В ЧИСТЕНЬКОМ"',
                    text: 'с. Чистенькое, Севастопольское шоссе, д. 6',
                    content: ''
                },
                {
                    type: 'email',
                    icon: './assets/email.png',
                    title: 'Электронная почта',
                    subtitle: '',
                    text: '',
                    content: 'pamyatnikivsimferopole@gmail.com'
                },

            ]
        })
    }

    renderItems() {
return this.state.data.map((item, index)=> {
    return(
        <li key={index} className={`contacts-block__wrapper contacts-block__${item.type}`}>
            <div className="contacts-block__icon">
                <img alt="contacts" src={item.icon}/>
            </div>
            <div className="contacts-block__content">
                {item.title ? <div className="contacts-block__title">{item.title}</div> : ''}
                {item.subtitle ? <div className="contacts-block__subtitle">{item.subtitle}</div> : ''}
                {item.text ? <div className="contacts-block__text">{item.text}</div> : ''}
                {item.type === 'phone' ? <a href="tel:+79787890452" className="contacts-block__phone">+7 (978) 789-04-52</a> : ''}
                {item.type === 'email' ?  <a className="contacts-block__email" href="mailto:pamyatnikivsimferopole@gmail.com">tsapunvlad@gmail.com</a> : ''}

            </div>
        </li>
    )
})
    }


    render() {

        return (
            <div className="contacts-block">
                <div className="top_title">Звоните! Ответим на все вопросы.</div>
                <div className="contacts-block__container">

                    <div className="contacts-block__image">
                        <img src="./assets/man.png" alt='man' />
                    </div>


                        <ul className="contacts-block__content">
                            {this.renderItems()}
                        </ul>

                </div>
            </div>

        )
    }


}

export default HomeContacts
