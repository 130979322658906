export default function setCurrentProduct(product) {
    if(product) {
        return {
            type: 'SET_CURRENT_PRODUCT',
            payload: product,
        }
    } else {
        return {
            type: 'SET_CURRENT_PRODUCT',
            payload: false,
        }
    }

}
