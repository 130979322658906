import React, {Component} from 'react';
import './Remote.scss';

// import Communication from '../../Communication';

class Remote extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],

        };
    }

    componentDidMount() {
        // Communication.fetchCategoriesData().then((result) => {
        //     this.setState({ catalogData: result })
        // })
        //
        // if (window.location.pathname === '/') {
        //     this.setState({ catalogPage: false })
        // }

        this.setState({
            data: [
                {
                    title: '',
                    img: '/assets/customer-service.svg',
                    text: 'Наш менеджер всегда на связи и готов ответить на все ваши вопросы'
                },
                {
                    title: '',
                    img: '/assets/manufacturing.svg',
                    text: 'Изготовление памятника под ключ'
                },
                {
                    title: '',
                    img: '/assets/camera.svg',
                    text: 'Фото и видео отчеты в процессе изготовления и установки'
                },
            ]
        })
    }

    renderItems() {
        return this.state.data.map((item, index) => {
            return (
                <div key={index} className="remote__container">
                    <div className="remote__item">
                        <div className="remote__image">
                            <img src={item.img} alt="steepimage"/>
                        </div>
                        <div className="remote__title">{item.title}</div>
                        <p className="remote__text">{item.text}</p>
                    </div>
                </div>
            )

        })

    }


    render() {

        return (
            <div className="steeps">
                <div className="top_title">Удаленный заказ памятника</div>
                <div className="steeps__wrapper" >
                    {this.renderItems()}
                </div>
            </div>

        )
    }
}

export default Remote;
