const initialState = {
    menu: '',
    category: ''
};

export default function interafaceData(state = initialState, action) {
    switch (action.type) {
        case 'SET_MENU':
            return {...state, menu: action.payload};

        case 'SET_CATEGORY':
            return {...state, category: action.payload};

        case 'SET_CURRENT_PRODUCT':
            return {...state, product: action.payload};

            case 'SET_CURRENT_ARTICLE':
            return {...state, article: action.payload};

        default:
            return state;
    }
}
