import React, {Component} from 'react';
import './Contacts.scss';
import {Helmet} from "react-helmet";

// import Communication from '../../Communication';

class Contacts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],

        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        // Communication.fetchCategoriesData().then((result) => {
        //     this.setState({ catalogData: result })
        // })
        //
        // if (window.location.pathname === '/') {
        //     this.setState({ catalogPage: false })
        // }

        this.setState({
            data: [
                {
                    type: 'phone',
                    icon: './assets/phone.png',
                    title: 'Наш контактный телефон в Симферополе',
                    subtitle: '',
                    text: '',
                    content: '+7 (978) 789-04-52'

                },
                {
                    type: 'address',
                    icon: './assets/address.png',
                    title: 'Адрес в Симферополе ',
                    subtitle: '"ПАМЯТНИКИ В ЧИСТЕНЬКОМ"',
                    text: 'с. Чистенькое, Севастопольское шоссе, д. 6',
                    content: ''
                },
                {
                    type: 'phone',
                    icon: './assets/phone.png',
                    title: 'Наш контактный телефон в Белогорске',
                    subtitle: '',
                    text: '',
                    content: '+7 (978) 594-34-54'

                },
                {
                    type: 'address',
                    icon: './assets/address.png',
                    title: 'Адрес в Белогорске',
                    text: 'г. Белогорск, ул. Николая Бойко, 16а',
                    content: ''
                },
                {
                    type: 'email',
                    icon: './assets/email.png',
                    title: 'Электронная почта',
                    subtitle: '',
                    text: '',
                    content: 'pamyatnikivsimferopole@gmail.com'
                },
                {
                    type: 'schedule',
                    icon: './assets/email.png',
                    title: 'Время работы',
                    subtitle: '',
                    text: 'Пн.-Пт. с 9:00 до 17:00',
                    content: ''
                },


            ]
        })
    }

    renderItems() {
        return this.state.data.map((item, index) => {
            return (
                <li key={index} className={`contacts__wrapper contacts__${item.type}`}>
                    {/*<div className="contacts-block__icon">*/}
                    {/*    <img alt="contacts" src={item.icon}/>*/}
                    {/*</div>*/}
                    <div className="contacts__content">
                        {item.title ? <div className="contacts__title">{item.title}</div> : ''}
                        {item.subtitle ? <div className="contacts__subtitle">{item.subtitle}</div> : ''}
                        {item.text ? <div className="contacts__text">{item.text}</div> : ''}
                        {item.type === 'phone' ?
                            <a href="tel:+79787890452" className="contacts__phone">{item.content}</a> : ''}
                        {item.type === 'email' ? <a className="contacts__email"
                                                    href="mailto:pamyatnikivsimferopole@gmail.com">{item.content}</a> : ''}

                    </div>
                </li>
            )
        })
    }


    render() {

        return (
            <>
                <Helmet>
                    <title>Контакты</title>
                    <meta name="description"
                          content="Адрес и контакты нашей компании."/>
                    <meta name="keywords"
                          content="Памятники в Чистеньком, Памятники в Симферополе"/>
                </Helmet>

                <div className="contacts">
                    <h1 className="top_title">Контакты</h1>
                    <div className="contacts__container">
                        {this.renderItems()}
                    </div>

                    <div id="third-party-container" className="contacts__map">
                        <iframe src="https://yandex.ru/map-widget/v1/?z=12&amp;ol=biz&amp;oid=1729894983" width="100%"
                                height="400" scroll="false" frameBorder="0" title={'map'}></iframe>
                    </div>


                </div>
            </>

        )
    }

}

export default Contacts
