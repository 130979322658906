import React, {Component} from 'react';
import './HomeComments.scss';
import Button from '../Button/Button';
import {Link} from "react-router-dom";
import Communication from "../../Communication";
import FormattingHandler from '../../FormattingHandler';

class HomeComments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            siteComments: [],

        };
    }

    async componentDidMount() {

        window.scrollTo(0, 0);
        let siteComments;

       /* Communication.fetchRecalls().then((result) => {

            siteComments = result.filter((v) => {
                return v._id === 'text'
            });

            this.setState({
                siteComments: siteComments[0].items.slice(0, 3)
            })
        });*/
        const result = await  Communication.fetchRecalls();
        siteComments = result.filter((v) => {
            return v._id === 'text'
        });

        this.setState({
            siteComments: siteComments[0].items.slice(0, 3)
        })
    }

    renderItems() {
        const {siteComments} = this.state;
        return siteComments.map((item, index) => {
            return (
                <div key={index} className="home-comments__site-item site-item">
                    <div className="site-item__name">{item.title}</div>
                    <div className="site-item__date">{FormattingHandler.formatDate(item.date)}</div>
                    <div className="site-item__text">{item.text}</div>
                </div>
            )
        })
    }

    render() {
        return (
            <div className="home-comments">
                <div className="top_title">ОТЗЫВЫ</div>
                <div className="home-comments__container">
                    {this.renderItems()}
                    <Link to="/reviews.html">
                        <Button className="home-comments__button" type='gallery' name="Посмотреть все отзывы"/>
                    </Link>
                </div>

            </div>
        )
    }
}

export default HomeComments
