import React from 'react';
import './Advantages.scss'

const Advantages = () => {

    return (<div className="advantages container">
                <div className="advantages__wrapper">
                    <div className="advantages__item">
                        <div className="advantages__number">12</div>
                        <p>лет<br/>опыта</p>
                    </div>
                </div>
                <div className="advantages__wrapper">
                    <div className="advantages__item">
                        <div className="advantages__number">180</div>
                        <p>форм<br/>памятников</p>
                    </div>
                </div>
                <div className="advantages__wrapper">
                    <div className="advantages__item">
                        <div className="advantages__number">63</div>
                        <p>тонны гранита<br/>на складе</p>
                    </div>
                </div>
            </div>)
};

export default Advantages;