import React, {Component} from 'react';
import {BrowserRouter, Route, Switch,} from 'react-router-dom';
import theme from './theme/muiTheme';
import  { ThemeProvider as MuiThemeProvider }  from '@material-ui/core/styles'
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Sidebar from './components/Sidebar/Sidebar';
import Promo from './components/Promo/Promo';
import HomePage from './components/HomePage/HomePage';
import OptPage from './components/Opt/OptPage';
import Catalog from './components/Catalog/Catalog';
import ProductPage from './components/ProductPage/ProductPage';
import Articles from './components/Articles/Articles';
import ArticleItem from './components/ArticleItem/ArticleItem';
import Contacts from './components/Contacts/Contacts';
import Gallery from './components/Gallery/Gallery';
import ScrollToTop from './components/ScrollToTop/ScrollToTop'
import './App.scss';
import HomeMap from "./components/HomeMap/HomeMap";
import Comments from './components/Comments/Comments'
import HomeComments from './components/HomeComments/HomeComments';
import { YMInitializer } from 'react-yandex-metrika';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module'
import MountingPage from "./components/Mounting/MountingPage";
import Page404 from "./components/Page404/Page404";

class App extends Component {

    render() {
        if(process.env.REACT_APP_GOOGLE==='active') {
            const tagManagerArgs = {
                gtmId: 'GTM-W9RJZVL'
            }

            TagManager.initialize(tagManagerArgs);
            ReactGA.initialize('UA-87944824-1');
        }
        return (

            <MuiThemeProvider theme={theme}>
                <BrowserRouter>
                    {/*{window.location.pathname === '/katalog.html' ? <Redirect to="/catalog.html"/>: ''}*/}
                    {/*{window.location.pathname.match('katalog/')  ? <Redirect to="/catalog.html"/>: ''}*/}
                    <Header/>
                    <div className="container">
                        <div className="container__left-side left-side">
                            <Sidebar/>
                           {/* <Promo/>*/}
                            {/*показывать блок c отзывами везде, кроме страницы с отзывами*/}
                            <Switch>
                                <Route exact path='/' component={Promo } />
                                <Route path='/catalog.html' component={Promo} />
                                <Route exact path="/catalog/:id"  component={Promo} />
                                <Route path="/articles.html"  component={Promo} />
                                <Route exact path="/articles/:article"  component={Promo} />
                            </Switch>
                            <Switch>
                                <Route exact path='/' component={HomeComments } />
                                <Route path='/catalog.html' component={HomeComments} />
                                <Route exact path="/catalog/:id"  component={HomeComments} />
                                <Route path="/articles.html"  component={HomeComments} />
                                <Route exact path="/articles/:article"  component={HomeComments} />
                            </Switch>
                           {/* <Switch>
                                <Route exact path="/" component={this.withTracker(HomeComments)}/>
                                <Route path="/catalog.html" component={this.withTracker(HomeComments)}/>
                                <Route exact path="/catalog/:id" component={this.withTracker(HomeComments)}/>
                                <Route path="/articles.html" component={this.withTracker(HomeComments)}/>
                                <Route exact path="/articles/:article" component={this.withTracker(HomeComments)}/>
                                <Route exact path="/contact.html" component={this.withTracker(HomeComments)}/>

                            </Switch>*/}

                        </div>
                        <div className="container__right-side right-side">
                            <Switch>
                                <Route exact path="/" component={this.withTracker(HomePage)}/>

                                <Route exact path="/404.html" component={this.withTracker(Page404)}/>
                                <Route exact path="/catalog.html" component={this.withTracker(Catalog)}/>
                                <Route exact path="/catalog/:type" component={this.withTracker(Catalog)}/>
                                <Route exact path="/products.html" component={this.withTracker(Catalog)}/>
                                <Route exact path="/products/:id" component={this.withTracker(ProductPage)}/>
                                <Route exact path="/articles.html" component={this.withTracker(Articles)}/>
                                <Route path="/articles/:article" component={this.withTracker(ArticleItem)}/>
                                <Route exact path="/contact.html" component={this.withTracker(Contacts)}/>
                                <Route path="/gallery.html" component={this.withTracker(Gallery)}/>
                                <Route path="/reviews.html" component={this.withTracker(Comments)}/>

                                <Route exact path="/katalog.html" component={this.withTracker(Catalog)}/>
                                <Route exact path="/katalog/:id" component={this.withTracker(ProductPage)}/>
                                <Route exact path="/optovikam.html" component={this.withTracker(OptPage)}/>
                                <Route exact path="/ustanovka.html" component={this.withTracker(MountingPage)}/>
                            </Switch>
                        </div>
                    </div>

                    {(process.env.REACT_APP_GOOGLE==='active') ?<YMInitializer accounts={[41103649]} options={{webvisor: true, accurateTrackBounce:true}} version="2"  />:""}
                    <HomeMap/>
                    <Footer/>
                    <ScrollToTop/>
                </BrowserRouter>
            </MuiThemeProvider>
        );
    }

    withTracker(WrappedComponent, options = {}) {

        const trackPage = (page) => {
            if(process.env.REACT_APP_GOOGLE==='active') {
                ReactGA.set({
                    page,
                    ...options
                });
                ReactGA.pageview(page);
            }
            // ReactPixel.pageView();
        };

        const HOC = class extends Component {
            componentDidMount() {
                const page = this.props.location.pathname;
                trackPage(page);
            }

            UNSAFE_componentWillReceiveProps(nextProps) {
                const currentPage = this.props.location.pathname;
                const nextPage = nextProps.location.pathname;

                if (currentPage !== nextPage) {
                    trackPage(nextPage);
                }
            }

            render() {
                return <WrappedComponent {...this.props} />;
            }
        };

        return HOC;
    }
}

export default App;
