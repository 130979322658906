import React, {Component} from 'react';
import './WhyWe.scss';

// import Communication from '../../Communication';

class WhyWe extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],

        };
    }

    componentDidMount() {
        // Communication.fetchCategoriesData().then((result) => {
        //     this.setState({ catalogData: result })
        // })
        //
        // if (window.location.pathname === '/') {
        //     this.setState({ catalogPage: false })
        // }
        if (window.location.pathname === '/optovikam.html') {
            this.setState({
                data: [
                    {
                        title: 'ОПТ 76% объема продаж',
                        img: '/assets/why_we-1.png',
                        text: 'Около 76% объёма наших продаж составляют оптовые продажи мастерским. Для розничных клиентов это является показателем качества нашей продукции, а также ценового баланса.'
                    },
                    {
                        title: 'Представители карьера',
                        img: '/assets/why_we-2.png',
                        text: 'Обращаясь к нам, Вы доверитесь официальному представителю карьера по добыче натурального камня в нашем регионе. Для Вас это выражается в оптимальных ценах и сроках изготовления памятника.'
                    },
                    {
                        title: 'Работаем ответственно',
                        img: '/assets/why_we-3.png',
                        text: 'Опыт двенадцати лет работы показывает, что внимательность, ответственность и чуткость к проблемам и заботам наших клиентов, не пропадает зря - нас рекомендуют, о нас говорят, нас советуют.'
                    },
                    {
                        title: 'Удалённый заказ',
                        img: '/assets/why_we-4.png',
                        text: 'Возможность заказать надгробие удалённо, не присутствуя в Симферополе или Крыму. У нас эта услуга уже давно стала нормальной практикой, удобство которой успели оценить многие  клиенты.'
                    },
                    {
                        title: 'Ручная работа',
                        img: '/assets/why_we-5.png',
                        text: 'Как резка камня, так и нанесение изображений производится талантливыми резчиками и художниками вручную.'
                    },
                    {
                        title: 'Натуральный камень',
                        img: '/assets/why_we-6.png',
                        text: 'При производстве памятников мы используем только натуральный камень: гранит и его виды. Памятники из таких материалов устойчивы к влиянию окружающей среды: осадков, изменению температур и др.'
                    }
                ]});
        }else {
            this.setState({
                data: [
                    {
                        title: 'Опт и розница',
                        img: '/assets/why_we-1.png',
                        text: 'Около 76% объёма наших продаж составляют оптовые продажи мастерским. Для розничных клиентов это является показателем качества нашей продукции, а также ценового баланса.'
                    },
                    {
                        title: 'Представители карьера',
                        img: '/assets/why_we-2.png',
                        text: 'Обращаясь к нам, Вы доверитесь официальному представителю карьера по добыче натурального камня в нашем регионе. Для Вас это выражается в оптимальных ценах и сроках изготовления памятника.'
                    },
                    {
                        title: 'Работаем ответственно',
                        img: '/assets/why_we-3.png',
                        text: 'Опыт двенадцати лет работы показывает, что внимательность, ответственность и чуткость к проблемам и заботам наших клиентов, не пропадает зря - нас рекомендуют, о нас говорят, нас советуют.'
                    },
                    {
                        title: 'Удалённый заказ',
                        img: '/assets/why_we-4.png',
                        text: 'Возможность заказать надгробие удалённо, не присутствуя в Симферополе или Крыму. У нас эта услуга уже давно стала нормальной практикой, удобство которой успели оценить многие  клиенты.'
                    },
                    {
                        title: 'Ручная работа',
                        img: '/assets/why_we-5.png',
                        text: 'Как резка камня, так и нанесение изображений производится талантливыми резчиками и художниками вручную.'
                    },
                    {
                        title: 'Натуральный камень',
                        img: '/assets/why_we-6.png',
                        text: 'При производстве памятников мы используем только натуральный камень: гранит и его виды. Памятники из таких материалов устойчивы к влиянию окружающей среды: осадков, изменению температур и др.'
                    }
                ]
            })
        }
    }

    renderItems() {
        return this.state.data.map((item, index) => {
            return (
                <div key={index} className="why-we__item">
                    <div className="why-we__image">
                        <img src= {item.img}
                             alt="whyimage"/>
                    </div>
                    <div className="why-we__title">{item.title}</div>
                    <p className="why-we__text">{item.text}</p>
                </div>
            )

        })

    }


    render() {

        return (
            <div className="why-we">
                <div className="top_title">Почему мы?</div>
                <div className="why-we__container">
                    {this.renderItems()}
                </div>
            </div>

        )
    }
}

export default WhyWe;
