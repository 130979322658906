import React, {Component} from 'react';
import './Slider.scss';

class Slider extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeSlide: 0,
            interval: 5,
            autoplay: true,
            slides: [
                {
                    background: "/assets/banner_bg.jpg",
                },
                {
                    background: "/assets/banner_bg.jpg",
                },
                {
                    background: "/assets/banner_bg.jpg",
                }
            ]
        };
    }

    componentDidMount() {
        this.timerId = setInterval(() => {
            if (this.state.autoplay) {
                this.nextSlide();
            }
        }, this.state.interval * 1000);
    }

    componentWillUnmount() {
        clearInterval(this.timerId);
    }


    nextSlide() {
        let slide = this.state.activeSlide + 1 < this.state.slides.length
            ? this.state.activeSlide + 1
            : 0;
        this.setState({
            activeSlide: slide
        });
    }

    renderSlides() {

        return this.state.slides.map((slide, index) => {
            return (
                <div
                    key={index}
                    className="slider__slide"
                    data-active={index === this.state.activeSlide}
                    style={{'backgroundImage': `url(${slide.background})`}}

                >
                </div>

            );
        });
    }

    render() {

        return (

            <div className="slider">
                {this.renderSlides()}


            </div>
        );
    }
}


export default Slider;