export default function setCurrentArticle(article) {
    if(article) {
        return {
            type: 'SET_CURRENT_ARTICLE',
            payload: article,
        }
    } else {
        return {
            type: 'SET_CURRENT_ARTICLE',
            payload: false,
        }
    }

}
