import React, {Component} from 'react';
import './OrderSteps.scss';

// import Communication from '../../Communication';

class OrderSteps extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],

        };
    }

    componentDidMount() {
        // Communication.fetchCategoriesData().then((result) => {
        //     this.setState({ catalogData: result })
        // })
        //
        // if (window.location.pathname === '/') {
        //     this.setState({ catalogPage: false })
        // }

        this.setState({
            data: [
                {
                    title: 'Шаг первый',
                    img: './assets/steep-1.png',
                    text: 'Вы выбираете форму и комплектность - мы изготавливаем эскиз.'
                },
                {
                    title: 'Шаг второй',
                    img: './assets/steep-2.png',
                    text: 'Внесение правок в эскиз, его утверждение предоплата.'
                },
                {
                    title: 'Шаг третий',
                    img: './assets/steep-3.png',
                    text: 'Изготовление, установка памятника в срок 3-4 недели.'
                },
            ]
        })
    }

    renderItems() {
        return this.state.data.map((item, index) => {
            return (
                <div key={index} className="steeps__container">
                    <div className="steeps__item">
                        <div className="steeps__image">
                            <img src={item.img} alt="steepimage"/>
                        </div>
                        <div className="steeps__title">{item.title}</div>
                        <p className="steeps__text">{item.text}</p>
                    </div>
                </div>
            )

        })

    }


    render() {

        return (
            <div className="steeps">
                <div className="top_title">Как происходит заказ памятника?</div>
                <div className="steeps__wrapper" >
                    {this.renderItems()}
                </div>
            </div>

        )
    }
}

export default OrderSteps;
