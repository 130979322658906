import React, {Component} from 'react';
import './Articles.scss';
import {Link} from 'react-router-dom'
import {Helmet} from "react-helmet";
import {connect} from 'react-redux';
import Communication from '../../Communication';
import setMenuAction from "../../actions/actionMenu";

class Articles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            articles: [],
            menudata: {},
        };
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        const result = await  Communication.fetchArticles()
        this.setState({ articles: result });
        let {menu} = this.props;


        console.log('MENU:', menu);
        if (!menu) {
             menu = await  Communication.fetchMenuData();
            console.log('MENU2:', menu);
        }
        console.log('window.location.pathname:', window.location.pathname);
        if (window.location.pathname === '/articles.html') {
            const menudata=menu.find((value)=>{
                return value.link ===window.location.pathname
            })
            this.setState({menudata: menudata})
        }

    }

    checkArticle(){
        console.log(' event click выбор статьи')
    }

    renderItems() {
        return this.state.articles.map((item, index) => {
            return (
                <div className="articles__item" key={index} onClick={this.checkArticle}>
                    <Link key={index} className="articles__link" to={`/articles/${item.url}`}>
                        <h3 className="articles__title">{item.title}</h3>
                        <div className="articles__desc">{item.desc}</div>
                    </Link>
                </div>

            )
        })

    }


    render() {
        const { menudata} = this.state;

        return (
            <>
                <Helmet>
                    <title>{menudata?.title}</title>
                    <meta name="description"
                          content={menudata?.description}/><meta name="keywords"
                          content="памятники, надгробия, надгробные плиты"/>
                </Helmet>

                <div className="articles">
                    <h1 className="top_title">Статьи</h1>
                    <h2 className="articles__subtitle">Статьи на тему заказа и покупки памятников и надгробий в
                        Симферополе, Севастополе, Ялте, Алуште, Евпатории и других городах Крыма.
                    </h2>
                    {this.renderItems()}
                </div>
            </>

        )
    }

}

// export default Articles


function mapDispatchToProps(dispatch) {
    return {
        setMenuFunction: (arr) => {
            dispatch(setMenuAction(arr))
        }
    }
}



function mapStateToProps(state) {

    return {
        menu: state.interafaceData.menu
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Articles);