import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
    palette: {
        primary: {
            main: '#ffffff'
        },
        secondary: {
            main: '#f33'
        }
    },
    overrides: {
        MuiDrawer: {
            paper: {
                background: '#1a1d24',
                color: 'white'
            },

        },
        MuiPaper: {
            root: {
                background: '#1a1d24',

            },
            elevation16: {
                boxShadow: 'none',
                alignItems: 'center'
            }

        },
        MuiBackdrop: {
            root: {
                backgroundColor: 'transparent',
            }
        },

        MuiInput: {
            root: {
                fontSize: '18px',
            },

        }

    }
})