import React, {Component} from 'react';
import './Gallery.scss';
import Button from '../Button/Button';
import ImageViewer from '../ImageViewer/ImageViewer'
import MiniSlider from "../MiniSlider/MiniSlider";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import Communication from "../../Communication";

// import Communication from '../../Communication';

class Gallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        Communication.fetchGallery().then((result) => {
            console.log(result, 'result')
            if (!result) {
                return
            }
            console.log("result",result);
            this.setState({data: result[0].images})
        });

    }

    renderItems() {
        return this.state.data.map((item, index) => {

            return (
                <div key={index} className="gallery__item">
                    <img
                        src={`/assets/photoGallery/${item}.jpg`} alt="gallery-item"
                        onClick={this.open.bind(this, index)}
                        className="home-gallery__img"/>
                </div>
            )
        })
    }


    render() {
        const {data} = this.state;
        return (
            <>
                <Helmet>
                    <title>Галерея наших работ</title>
                    <meta name="description"
                          content="Фотогалерея работ компании Памятники в Чистеньком - памятники и надгробия."/>
                    <meta name="keywords"
                          content="Памятники в Чистеньком, Памятники в Симферополе"/>
                </Helmet>

                <div className="gallery">
                    <div className="top_title">Фото наших работ</div>
                    <MiniSlider/>

                    <Link to="/catalog.html" className="gallery__link">
                        <Button className="gallery__button" type='catalog' name="Перейти в каталог"/>
                    </Link>

                    <div className="gallery__container">
                        {this.renderItems()}
                    </div>
                    <ImageViewer
                        images={data}
                        showIndex
                        showPreview
                        ref={(component) => this.modal = component}
                        url='photoGallery/'
                    />
                </div>
            </>
        )
    }

    open(index) {
        this.modal.open(index);
    }
}

export default Gallery
