import React, {Component} from 'react';
import './HomeGallery.scss';
import Button from '../Button/Button';
import ImageViewer from '../ImageViewer/ImageViewer'
// import Communication from '../../Communication';

class HomeGallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],

        };
    }

    componentDidMount() {
        // Communication.fetchCategoriesData().then((result) => {
        //     this.setState({ catalogData: result })
        // })
        //
        // if (window.location.pathname === '/') {
        //     this.setState({ catalogPage: false })
        // }

        this.setState({
            data: [
                {
                    img: 'homeGallery/025',
                    preview: 'homeGallery/025s',
                },

                {
                    img: 'homeGallery/027',
                    preview: 'homeGallery/027s',
                },
                {
                    img: 'homeGallery/030',
                    preview: 'homeGallery/030s',
                },
                {
                    img: 'homeGallery/028',
                    preview: 'homeGallery/028s',
                },
                {
                    img: 'homeGallery/019',
                    preview: 'homeGallery/019s',
                },
                {
                    img: 'homeGallery/021',
                    preview: 'homeGallery/021s',
                },
            ]
        })
    }

    renderItems() {
        return this.state.data.map((item, index) => {
            return (
                <div key={index} className="home-gallery__item">
                    <img  src={`/assets/${item.preview}.jpg`}  alt="gallery-item" onClick={this.open.bind(this, index)} className="home-gallery__img"/>
                </div>
            )

        })

    }


    render() {
        const { data } = this.state;
        return (
            <div className="home-gallery">
                <div className="top_title">Галерея наших работ</div>
                <div className="home-gallery__container">
                    {this.renderItems()}
                    <Button className="home-gallery__button" type='gallery' name="Посмотреть все фотографии"/>

                </div>
                <ImageViewer
                    images={data}
                    showIndex
                    showPreview
                    ref={(component) => this.modal = component}/>
            </div>

        )
    }
    open(index) {
        this.modal.open(index);
    }

}

export default HomeGallery
