import React, {Component} from 'react';
import './Button.scss';
class Button extends Component {

    render() {
        return (
            <button className={`button ${this.props.className ? this.props.className : ''}`} type={this.props.type} onClick={this.props.handleClick}>
                {this.props.name}
            </button>
        )
    }
}

export default Button;
