import React, {Component} from 'react';
import './ProductPage.scss';
import ImageViewer from '../ImageViewer/ImageViewer';
import Calculator from '../Calculator/Calculator';
import {Helmet} from "react-helmet";
import Communication from "../../Communication";
import setCurrentProduct from "../../actions/actionCurrentProduct";
import {connect} from "react-redux";
// import ReactGA from 'react-ga';
class ProductPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productQuantity: 0,
            currentImage: 0,
            currentProduct: '',
            selectedImages: '',
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.getData();
    }

   async getData() {

       const articlesData  = await  Communication.fetchArticlesData();
       let productData;

       if (articlesData?.length > 0) {
           productData = articlesData.find((item) => {
               return `${item.url}.html` === this.props.match.params.id
           });
       }
       if(productData)
       {
           this.props.setCurrentProductFn(productData.name);
           this.setState({
               currentProduct: productData,
           })
       }
    }


    renderRelatedImages() {
        const {currentProduct} = this.state;

        if (currentProduct?.works?.length < 1) {
            return ''
        }

        return (
            <div className='product-info__related-images related-images'>
                <h4 className='related-images__title'>ПРИМЕРЫ ПАМЯТНИКОВ</h4>
                <div className='related-images__container'>{
                        currentProduct.works.map((item, index) => {
                            return (<div key={index} className="related-images__item">
                                    <img src={`/assets/photoGallery/${item}.jpg`} alt="gallery-item"
                                         onClick={this.open.bind(this, index, currentProduct.works, 'photoGallery/')}
                                         className="related-images__img"/>
                                </div>)
                        })
                    }</div>
            </div>
        )

    }

    renderProduct() {
        const {currentProduct, currentImage} = this.state;

        if (!currentProduct) {
            return '';
        }

        //TODO: заглушка для фото
        return (<div className='product-info'>
                <div className='product-info__image-block'>
                    <img
                        srcSet={`/assets/articles/${currentProduct?.photos[this.state.currentImage]}.jpg 1x , /assets/articles/${currentProduct.photos[this.state.currentImage]}-2x.jpg 2x`}
                        className='product-info__image' alt='img'
                        onClick={this.open.bind(this, currentImage, currentProduct.photos, 'articles/')}/>
                    <ul className='preview-container'>
                        {this.productImages()}
                    </ul>
                    {this.renderRelatedImages()}
                </div>
                <div className='product-info__info'>
                    <Calculator currentProduct={currentProduct}/>
                </div>
            </div>)
    }

    changeCurrentImage(index) {
        this.setState({
            currentImage: index,
        });
    }

    productImages() {

        if (!this.state.currentProduct && this.state.currentProduct.photos.length < 1) {
            return ''
        }

        return this.state.currentProduct.photos.map((item, index) => {

            if (this.state.currentImage === index) {
                return (
                    <li className='preview-container__image-wrapper' key={index}>

                        <img
                            srcSet={`/assets/articles/${item}-preview.jpg 1x , /assets/articles/${item}-preview-2x.jpg 2x`}
                            className='preview-container__preview-image active'
                            onClick={this.changeCurrentImage.bind(this, index)}
                            alt='img'/>
                    </li>
                )
            } else {
                return (
                    <li className='preview-container__image-wrapper' key={index}>
                        <img
                            srcSet={`/assets/articles/${item}-preview.jpg 1x , /assets/articles/${item}-preview-2x.jpg 2x`}
                            className='preview-container__preview-image'
                            onClick={this.changeCurrentImage.bind(this, index)}
                            alt='img'/>
                    </li>
                )
            }
        })
    }

    render() {
        let {currentProduct, selectedImages, imageUrl} = this.state;
        if(currentProduct===undefined)
        {
            return ''
        }
        else
        return (
            <>
                <Helmet>
                    <title>{currentProduct.title}</title>
                    <meta name="Description"
                          content={currentProduct.description}/>
                    <meta name="keywords"
                          content="каталог памятников, памятники, надгробия, надгробные плиты"/>
                </Helmet>

                <div className="product-page">
                    <h1 className="product-page__title">{currentProduct.name}</h1>

                    <div className="top_title">НАЖМИТЕ "ОСТАВИТЬ ЗАЯВКУ", ЧТОБЫ МЫ ПЕРЕЗВОНИЛИ ВАМ!</div>


                    {this.renderProduct()}

                    <div className="product-page__description">{currentProduct.desc} {currentProduct.text}</div>
                    <div className="top_title">ТАКЖЕ ВОЗМОЖНО ИЗГОТОВЛЕНИЕ ПАМЯТНИКА ЛЮБОЙ ФОРМЫ ПО ВАШИМ ПРЕДПОЧТЕНИЯМ
                        И ПОЖЕЛАНИЯМ.
                    </div>
                    <ImageViewer
                        images={selectedImages}
                        showIndex
                        showPreview
                        ref={(component) => this.modal = component}
                        url={imageUrl}
                    />
                </div>
            </>
        )
    }

    open(index, images, url) {
        this.modal.open(index);
        this.setState({
            selectedImages: images,
            imageUrl: url
        })
    }
}

function mapStateToProps(state) {

    return {
        product: state.interafaceData.product,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setCurrentProductFn: (product) => {
            dispatch(setCurrentProduct(product))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductPage);



