// localStorage.clear();

const serverUrl = "https://pamyatniki-v-simferopole.ru/api/v1/";
// const CryptoJS = require('crypto-js');

const communication = {

    async fetchMenuData() {
        return fetch(serverUrl + 'menu')
            .then(response => response.json())
            .then(result => {
                return result.data
                // return decriptData(result)
            })
            .catch(e => {
                console.log(e);
            })
    },


    async fetchCategoriesData() {
        return fetch(serverUrl + 'group')
            .then(response => response.json())
            .then(result => Array.isArray(result.data) ? result.data : [])
            .catch(e => {
                console.log(e);
            })
    },

    async fetchArticlesData() {
        return fetch(serverUrl + 'article')
            .then(response => response.json())
            .then(result => Array.isArray(result.data) ? result.data : [])
            // .then(result => decriptData(result.data))
            .then((result)=>{
                result.sort((a, b) => {
                    return a.sortindex - b.sortindex
                });
                result.forEach((value)=>{
                    value.variants.sort((a,b)=>{
                        return a.sort > b.sort;
                    });
                });
                return result;
            })
            .catch(e => {
                console.log(e);
            })
    },

    async fetchArticles() {
        return fetch(serverUrl + 'essay')
            .then(response => response.json())
            .then(result => Array.isArray(result.data) ? result.data : [])
            // .then(result => decriptData(result.data))
            .catch(e => {
                console.log(e);
            })
    },

   async fetchCurrentArticle(url) {
        // console.log(serverUrl + 'essay/' + url);
        return fetch(serverUrl + 'essay/' + url)
            .then(response => response.json())
            .then(result => {
                 return result.data
            })
            .catch(e => {
                console.log(e);
            })
    },

    async fetchGallery() {
        return fetch(serverUrl + 'gallery')
            .then(response => response.json())
            .then(result => Array.isArray(result.data) ? result.data : [])
            .catch(e => {
                console.log(e);
            })
    },

    async fetchRecalls() {
        return fetch(serverUrl + 'recalls')
            .then(response => response.json())
            .then(result => {
                return result.data
            })
            .catch(e => {
                console.log(e);
            })
    },


    fetchNewRelation(contact, phone, material){
        // console.log(material, 'material')
        let searchParams = new URLSearchParams();
        searchParams.set('contact', contact);
        searchParams.set('phone', phone);

        if (material) {
            searchParams.set('material', material);
        }

        let dispatcherRequest = new Request(serverUrl + '/relation', {
            method: "POST",
            body:searchParams
        });

        return fetch(dispatcherRequest)

            .then(response => response.json())
            .then(result => {

                if(result.status === 200) {

                    return 'success'

                } else{

                    return 'error'
                }

            })
            .catch(e => {
                console.log(e);
            })
    }

 };

/*const decriptData = function (value) {
    console.log(value, 'value')
    const contentType = 'Content-Type Text';
    return JSON.parse(CryptoJS.enc.Utf8.stringify(CryptoJS.AES.decrypt(value, contentType,
        {
            keySize: 128 / 8,
            iv: contentType,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        })))
}*/;
export default communication;
