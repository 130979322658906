import React, {Component} from 'react';
import ym from 'react-yandex-metrika';
import ReactGA from 'react-ga';
// import Communication from '../../Communication';
import './Promo.scss';
import Button from "../Button/Button";
import Feedback from "../Feedback/Feedback";

class Promo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            promoData: [],
            openModal: false,
        };
        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidMount() {
        // Communication.fetchMenuData().then((result) => {
        //   this.setState({ menuData: result })
        // })

    }
    handleClickOpen() {

        if(process.env.REACT_APP_GOOGLE==='active') {
            ReactGA.event({
                category: 'klik_po_knopke',
                action: 'obratny_zvonok',
                label: 'main_banner'
            });
            ym('reachGoal','obratny_zvonok_main_banner');
        }
        this.setState({
            openModal: true
        })


    }

    handleClose() {
        this.setState({
            openModal: false
        })
    }


    render() {
        return (
            <div className="promo">
                <div className="top_title">Акция</div>
                <div className="promo__container">
                    <div className="promo__item">

                        <p className="promo__text">Скидка 5%, при заказе зимой, а также - уборка могил и обслуживание захоронений бесплатно, ваза в подарок. Возможность заказать изготовление памятника удаленно. Для мастерских - поставка камня оптом. Доставка по Крыму.
                        </p>

                        <p className="promo__text">Принимаем заявки по телефону ‎+79787890452, Telegram, Viber, WhatsApp, а также на имейл pamyatnikivsimferopole@gmail.com, либо в самом цеху.</p>

                        <p><img className="promo__image"
                                src="/assets/small_29780faee1e218bdc487cac57c0604f9.jpg"
                                title="Скидка 5%, при заказе зимой" alt="Скидка 5%, при заказе зимой"/></p>

                        <Button  className="button__banner-callback"
                                 type='callback'
                                 name="Обратный звонок"
                                 handleClick={this.handleClickOpen.bind(this)}/>

                    </div>
                </div>
                <Feedback name="ОБРАТНЫЙ ЗВОНОК" handleClose={this.handleClose} open={this.state.openModal}/>
            </div>

        );

    }
}

export default Promo

