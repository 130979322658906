import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Drawer, Hidden, IconButton,} from '@material-ui/core';
import {Close as CloseIcon} from '@material-ui/icons';

import Menu from './Menu';


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: 'red'
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },

    drawer: {
        [theme.breakpoints.up('md')]: {
            flexShrink: 0,
            backgroundColor: 'red'
        },

    },

    list: {
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    }

}));

const DrawerMenu = ({mobileOpen, handleDrawerClose}) => {
    const classes = useStyles();

    return (
        <nav className={classes.drawer}>
            <Hidden mdUp implementation="css">
                <Drawer
                    variant="temporary"
                    anchor="top"
                    open={mobileOpen}
                    onClose={handleDrawerClose}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    transitionDuration={400}
                >

                    <IconButton onClick={handleDrawerClose}>
                        <CloseIcon color="primary"/>
                    </IconButton>

                    <Menu handleDrawerClose={handleDrawerClose}/>
                </Drawer>
            </Hidden>

        </nav>
    )
};

export default DrawerMenu