import React, {Component} from 'react';
import './ImageViewer.scss';

const VISIBLE_INDICATORS_COUNT = 8;
const KEY_CODE = {
    LEFT: 37,
    RIGTH: 39
};
const OFFSET_DEFAULT = {
    x: 0,
    y: 0
};

class ImageWrapper extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            onload: false,
            zoom: 0,
            offset: OFFSET_DEFAULT,

        };
        this.handler = '';
        this.draggable = false;
        this.offsetRange = OFFSET_DEFAULT;
        this.clientOffset = {
            x: undefined,
            y: undefined
        };
    }

    loadImage(src) {

        this.setState({
            loading: true
        });

        this.src = new Image();
        this.src.src = src;
        this.src.onload = () => {
            if (!this.src) {
                return
            }

            this.setState({
                loading: false,
                onload: true
            })

        };
        this.src.onerror = () => {
            if (!this.src) {
                return
            }
            this.setState({
                loading: false,
                onload: false
            });

        }
    }

    resetOffset() {
        this.setState({
            offset: OFFSET_DEFAULT
        })
    }

    setOffsetRange() {
        const zoom = this.state.zoom;
        const dx = this.image.scrollWidth * (1 + zoom / 2) - this.imageOuter.clientWidth;
        const dy = this.image.scrollHeight * (1 + zoom / 2) - this.imageOuter.clientHeight;
        this.offsetRange = {
            x: Math.max(0, dx / 2),
            y: Math.max(0, dy / 2)
        }
    }

    onMoveStart(e) {
        if (!this.offsetRange.x && !this.offsetRange.y) {
            return;
        }

        this.clientOffset = {
            x: e.clientX,
            y: e.clientY
        };
        this.draggable = true;
    }

    onMove(e) {

        if (!e.clientX || !e.clientY || !this.draggable) {
            return;
        }

        const offset = {
            x: e.clientX - this.clientOffset.x,
            y: e.clientY - this.clientOffset.y,
        };

        this.clientOffset = {
            x: e.clientX,
            y: e.clientY
        };

        this.setState({
            offset: {
                x: this.state.offset.x + offset.x,
                y: this.state.offset.y + offset.y,
            }
        })

    }

    onMoveEnd(e) {
        if (!this.mounted) {
            return;
        }

        this.draggable = false;
      /*
        const offset = {
            x: Math.abs(this.state.offset.x),
            y: Math.abs(this.state.offset.y)
        };
        */

        if (Math.abs(this.state.offset.x) >= this.offsetRange.x) {
            this.setState({
                offset: {
                    x: this.state.offset.x < 0 ? Math.min(0, -(this.offsetRange.x)) : Math.max(0, this.offsetRange.x)
                }
            })

        }

        if (Math.abs(this.state.offset.y) >= this.offsetRange.y) {
            this.setState({
                offset: {
                    y: this.state.offset.y < 0 ? Math.min(0, -(this.offsetRange.y)) : Math.max(0, this.offsetRange.y)
                }
            });

        }
    }

    componentWillReceiveProps(nextProps) {

        if (this.props.image.img !== nextProps.image.img) {
            this.resetOffset();
            this.loadImage(nextProps.image.img);
            this.setState({
                zoom: 0
            });
        }
    }

    componentDidMount() {
        this.mounted = true;
        this.loadImage(this.props.image.img);
        this.handler = this.setOffsetRange.bind(this);
        window.addEventListener('resize', this.handler);
        document.documentElement.addEventListener("mouseup", this.onMoveEnd.bind(this));
    }

    componentWillUnmount() {
        this.mounted = false;
        if (!!this.src) {
            this.src = undefined;
        }
        window.removeEventListener('resize', this.handler);
        document.documentElement.removeEventListener("mouseup", this.onMoveEnd.bind(this));
    }

    render() {
        const {image, index, showIndex, url} = this.props;

        const {offset, zoom, loading} = this.state;

        const value = `translate3d(${offset.x}px, ${offset.y}px, 0px)`;
        const imageCls = `zoom-${zoom} image-outer ${this.draggable ? 'dragging' : ''}`;


        return (
            <div className="image-wrapper">
                <div
                    style={{transform: value}}
                    ref={(component) => this.imageOuter = component}
                    className={imageCls}>
                    {loading ? (
                        <div className="spinner">
                            <div className="bounce"></div>
                        </div>
                    ) : <img
                        className="image"
                        ref={(component) => this.image = component}
                        srcSet={`/assets/${url}${image}.jpg 1x , /assets/${url}${image}-2x.jpg 2x`}
                        alt={image.title || ''}
                        draggable={false}
                        onDragStart={(e) => e.preventDefault()}
                        onMouseMove={this.onMove.bind(this)}
                        onMouseDown={this.onMoveStart.bind(this)}
                        onMouseUp={this.onMoveEnd.bind(this)}/>}
                </div>
                <div className="tool-bar">
                    {showIndex && <div className="index-indicator">{index}</div>}
                </div>
            </div>
        )
    }

}


class Viewer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeIndex: this.props.activeIndex
        }
    }

    renderIndicators(list) {
        const activeIndex = this.state.activeIndex;
        const ret = Math.round(VISIBLE_INDICATORS_COUNT / 2);
        const length = list.length;

        return list.map((item, index) => {

            const isActive = activeIndex === index;
            const itemInvisible = length > VISIBLE_INDICATORS_COUNT && (index < Math.min(length - VISIBLE_INDICATORS_COUNT - 1, activeIndex - ret) || index > Math.max(activeIndex + ret, VISIBLE_INDICATORS_COUNT));

            const itemCls = `indicators-item ${isActive ? 'active' : ''} ${itemInvisible ? 'invisible' : ''} ${this.props.showPreview ? 'preview' : ''}`;
            const url = this.props.url || '';
            return (
                <div
                    key={index}
                    className={itemCls}
                    onClick={this.itemControl.bind(this, index)}>
                    {this.props.showPreview && (
                        <div className="image" style={{background: `url(/assets/${url}${item}-preview.jpg)`}}></div>
                    )}
                </div>
            )

        })

    }

    onPrev() {
        let index = (this.state.activeIndex + this.props.images.length - 1) % this.props.images.length;
        this.itemControl(index);
    }

    onNext() {
        let index = (this.state.activeIndex + 1) % this.props.images.length;
        this.itemControl(index);
    }

    itemControl(index) {
        if (index === this.state.activeIndex) {
            return
        }

        this.setState({
            activeIndex: index
        })

    }

    onKeyDown(e) {
        if (!this.mounted) return;
        e.stopPropagation();

        switch (e.which || e.keyCode) {
            case KEY_CODE.LEFT:
                this.onPrev();
                break;
            case KEY_CODE.RIGTH:
                this.onNext();
                break;
            default:
        }
    }

    componentDidMount() {
        this.mounted = true;
        document.documentElement.addEventListener("keydown", this.onKeyDown.bind(this));
    }

    componentWillUnmount() {
        this.mounted = false;
        document.documentElement.removeEventListener("keydown", this.onKeyDown.bind(this));
    }

    render() {
        const {images, showIndex, prefixCls, url} = this.props;
        const {activeIndex} = this.state;
        const indicatorVisible = images.length > 1;


        return (
            <div className={`react-image-viewer ${prefixCls}-image-viewer`}
                 ref={(component) => this.container = component}>
                <ImageWrapper
                    showIndex={showIndex}
                    index={`${activeIndex + 1}/${images.length}`}
                    image={images[activeIndex]}
                    url={url ? url : ''}
                />
                {indicatorVisible ?
                    <div className="direction-control-button">
                        <div className="prev-button button"
                             onClick={this.onPrev.bind(this)}>
                            <div className="bar"></div>
                        </div>
                        <div className="next-button button"
                             onClick={this.onNext.bind(this)}>
                            <div className="bar"></div>
                        </div>
                        <div className="indicators">
                            {indicatorVisible && this.renderIndicators(images)}
                        </div>
                    </div>
                    : null}
            </div>
        )
    }
}


class ImageViewer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            activeIndex: undefined
        }
    }

    open(activeIndex) {
        this.setState({
            visible: true,
            activeIndex: activeIndex || 0
        });
    }

    close() {
        this.setState({
            visible: false,
            activeIndex: undefined
        });
    }

    render() {
        const {images, prefixCls, showIndex, showPreview, url} = this.props;

        const {activeIndex} = this.state;

        return this.state.visible ? (
            <div className='modal'>
                <Viewer
                    showPreview={showPreview}
                    showIndex={showIndex}
                    prefixCls={prefixCls}
                    activeIndex={activeIndex}
                    images={images}
                    url={url}
                />
                <div className='close-button'
                     onClick={this.close.bind(this)}>
                </div>
            </div>
        ) : null;
    }
}


export default ImageViewer
