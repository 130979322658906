import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import Communication from '../../Communication';
import './Menu.scss';
import setMenuAction from '../../actions/actionMenu';
import {connect} from 'react-redux';
import setCategoryAction from "../../actions/actionChangeCategory";
import setCurrentProduct from "../../actions/actionCurrentProduct";
import setCurrentArticle from "../../actions/actionCurrentArticle";

class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menuData: [],
            mobileDevice: false,

        };
        this.handleClick = this.handleClick.bind(this);
        this.changeCategory = this.changeCategory.bind(this);
    }

    async componentDidMount() {
        /*Communication.fetchMenuData().then((result) => {

            if(!result) {
                return
            }
            //записала в стор
            let sortResult = result.sort((a, b) => {
                return a.sortindex - b.sortindex
            });
            this.setState({menuData: sortResult}, this.props.setMenuFunction(sortResult))

        });*/
        const result = await Communication.fetchMenuData();
        if(!result) {
            return
        }
        //записала в стор
        let sortResult = result.sort((a, b) => {
            return a.sortindex - b.sortindex
        });

        this.setState({menuData: sortResult}, this.props.setMenuFunction(sortResult))
        //TODO: перенести и брать из стора
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            console.log('1244')
            this.setState({
                mobileDevice: true
            })
        }
    }

    handleClick() {
        console.log('event click переход на другую страницу')
        this.props.setCatFunction(false);
        this.props.setCurrentProductFn(false); //обнуляю выбранный товар
        this.props.setArticleFunction(false);

        if( this.props.handleDrawerClose) {
            this.props.handleDrawerClose()
        }
    }

    changeCategory(name) {
        console.log('event click выбор категории');
        this.props.setCatFunction(name);
        if( this.props.handleDrawerClose) {
            this.props.handleDrawerClose()
        }

    }

    renderSubCategories(subcategories) {

        return (
            <div className="menu__sublinks">

                {subcategories.map((subItem, index) => {
                    return (

                        <NavLink key={index} className='menu__sublink'
                                 to={`/catalog/${subItem.url}.html`}
                                 onClick={this.changeCategory.bind(this, subItem.name)}
                        >
                            {subItem.name.toUpperCase()}
                        </NavLink>

                    )
                })
                }
            </div>
        )
    }

    renderMenuItems() {
        const {menuData} = this.state;
        if (menuData.length > 0) {
            return menuData.map((item, index) => {

                if(item.link === "/reviews.html")
                {
                    return('')
                }
                else if(item.link === "/articles.html")
                {
                    return(<div key={index} className="menu__item">
                        <NavLink
                            exact
                            className="menu__link"
                            activeClassName='active'
                            to={item.link === '/' ? '/' : `/ustanovka.html`}
                            onClick={() => {
                                this.handleClick()
                            }}
                        >УСТАНОВКА
                        </NavLink>
                    </div>)
                }
                else
                {
                return (
                    <div key={index} className="menu__item">
                        <NavLink
                            exact
                            className="menu__link"
                            activeClassName='active'
                            to={item.link === '/' ? '/' : `${item.link}`}
                            onClick={() => {
                                 this.handleClick()
                            }}
                        >
                            {item.name}
                        </NavLink>
                        {item.subcategories.length > 0 ? this.renderSubCategories(item.subcategories) : '' }
                    </div>

                )
                }
            })

        } else {
            return '';
        }
    }

    render() {
        return (
            <div className="menu">
                {this.renderMenuItems()}
                <div className="menu__link" > <a className="menu__link" href={"tel:+79787890452"}>+79787890452</a><a className="menu__link"  href={"mailto:pamyatnikivsimferopole@gmail.com"}>pamyatnikivsimferopole@gmail.com</a></div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        menu: state.interafaceData.menu,

    }
}

function mapDispatchToProps(dispatch) {
    return {
        setMenuFunction: (arr) => {
            dispatch(setMenuAction(arr))
        },
        setCatFunction: (category) => {
            dispatch(setCategoryAction(category))
        },
        setCurrentProductFn: (product) => {
            dispatch(setCurrentProduct(product))
        },
        setArticleFunction: (article) => {
            dispatch(setCurrentArticle(article))
        },

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu);

