import React, {Component} from 'react';
import './Comments.scss';
import {Helmet} from "react-helmet";
import MiniSlider from "../MiniSlider/MiniSlider";
import {Link} from "react-router-dom";
import Button from "../Button/Button";
import ImageViewer from "../ImageViewer/ImageViewer";
import Feedback from "../Feedback/Feedback";
import Communication from "../../Communication";
import FormattingHandler from '../../FormattingHandler';

class Comments extends Component {
    constructor(props) {
        super(props);
        this.state = {

            openModal: false
        };
        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        let siteComments;
        let imageComments;

       /* Communication.fetchRecalls().then((result) => {

            console.log(result, 'result')
            siteComments = result.filter((v)=> {
                return v._id === 'text'
            });

            imageComments = result.filter((v)=> {
                return v._id === 'image'
            });

            this.setState({
                siteComments:siteComments[0].items,
                imageComments:imageComments[0].items[0].image
            })
        });
        */

        const result = await Communication.fetchRecalls();

        siteComments = result.filter((v)=> {
            return v._id === 'text'
        });

        imageComments = result.filter((v)=> {
            return v._id === 'image'
        });

        this.setState({
            siteComments:siteComments[0].items,
            imageComments:imageComments[0].items
            // imageComments:imageComments[0].items[0].image
        })

    }

    handleClickOpen() {
        this.setState({
            openModal: true
        })
    }

    handleClose() {
        this.setState({
            openModal: false
        })
    }


    renderWrittenComments() {
        // console.log(this.state.imageComments)
        if(!this.state.imageComments) {
            return
        }
        return this.state.imageComments.map(({image}, index) => {
            // console.log(item, 'item')
            ///*onClick={this.open.bind(this, index)} */
            return (
                <div key={index} className="comments__written-item">
                    <img  src={`/assets/comments/${image}.jpg`}  alt="comment-item"  className="comments__written-img"/>
                </div>
            )
        })
    }

    renderSiteComments(siteComments) {
        return siteComments.map((item, index) => {
            return (
                <div key={index} className="comments__site-item site-item">
                    <div className="site-item__name">{item.title}</div>
                    <div className="site-item__date">{FormattingHandler.formatDate(item.date)}</div>
                    <div className="site-item__text">{item.text}</div>
                </div>
            )
        })
    }

    render() {
        const {imageComments, siteComments} = this.state;

        if(!siteComments) {
            return ''
        }

        return (
            <>
                <Helmet>
                    <title>Отзывы</title>
                    <meta name="description"
                          content="Отзывы о компании Памятники в Чистеньком"/>
                    <meta name="keywords"
                          content="Памятники в Чистеньком, Памятники в Симферополе"/>
                </Helmet>

                <div className="comments">
                    <div className="top_title">ОТЗЫВЫ О НАС И НАШИХ РАБОТАХ</div>
                    <MiniSlider/>

                    <Link to="/catalog.html" className="article-item__link">
                        <Button className="article-item__button" type='catalog' name="Перейти в каталог"/>
                    </Link>

                    <div className="comments__site-container">
                        <div className="top_title">ОТЗЫВЫ НА САЙТЕ</div>
                        <Button  className="button__comment comments__button"
                                 type='comment'
                                 name="Оставить отзыв"
                                 handleClick={this.handleClickOpen.bind(this)}
                        />
                        <Feedback type="reviews" name="ОСТАВИТЬ ОТЗЫВ" handleClose={this.handleClose} open={this.state.openModal}/>
                      {this.renderSiteComments(siteComments)}
                    </div>
                    <div className="top_title">ПИСЬМЕННЫЕ ОТЗЫВЫ</div>
                    <div className="comments__written-container">
                        {this.renderWrittenComments()}
                        <ImageViewer
                            images={imageComments}
                            showIndex
                            showPreview
                            ref={(component) => this.modal = component}
                            url='comments/'/>
                    </div>
                </div>
            </>

        )
    }
    open(index) {
        this.modal.open(index);
    }

}

export default Comments
