import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import './Footer.scss';

// import Communication from '../../Communication';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            contactsData: []
        };
    }

    componentDidMount() {
        // Communication.fetchCategoriesData().then((result) => {
        //     this.setState({ catalogData: result })
        // })
        //

        this.setState({
            data: [
                {name: 'Главная', url: '/'},
                {name: 'Каталог', url: '/catalog.html'},
                {name: 'Галерея', url: '/gallery.html'},
                {name: 'Статьи', url: '/articles.html'},
                {name: 'Контакты', url: '/contact.html'}],
            contactsData: [
                {
                    type: 'phone',
                    icon: './assets/phone.png',
                    title: 'Наш контактный телефон',
                    subtitle: '',
                    text: '',
                    content: '+7 (978) 789-04-52'

                },
                {
                    type: 'email',
                    icon: './assets/email.png',
                    title: 'Электронная почта',
                    subtitle: '',
                    text: '',
                    content: 'pamyatnikivsimferopole@gmail.com'
                },
                {
                    type: 'address',
                    icon: './assets/address.png',
                    title: 'Где мы находимся',
                    subtitle: '"ПАМЯТНИКИ В ЧИСТЕНЬКОМ"',
                    text: 'с. Чистенькое, Севастопольское шоссе, д. 6',
                    content: ''
                },
                {
                    type: 'address',
                    icon: './assets/address.png',
                    title: '',
                    text: 'г. Белогорск, ул. Николая Бойко, 16а',
                    content: ''
                },

            ]
        });

    }

    renderItems() {
        return this.state.data.map((item, index) => {
            return (
                <NavLink exact
                         key={index}
                         className="footer__menu-link"
                         activeClassName='active'
                         to={item.url}
                >
                    {item.name}
                </NavLink>
            )
        })
    }

    renderContactItems() {
        return this.state.contactsData.map((item, index) => {
            return (
                <li key={index} className={`contacts__item contacts__${item.type}`}>

                    {item.title ? <div className="contacts__title">{item.title}</div> : ''}
                    {item.type === 'address' ?
                        <div className="contacts__address-content">{item.subtitle} {item.text}</div> : ''}
                    {item.type === 'phone' ?
                        <a href="tel:+79787890452" className="contacts__link">+7 (978) 789-04-52</a> : ''}
                    {item.type === 'email' ?
                        <a className="contacts__link" href="mailto:pamyatnikivsimferopole@gmail.com">pamyatnikivsimferopole@gmail.com</a> : ''}
                </li>
            )
        })
    }

    render() {

        return (

            <div className="footer">

                <div className="footer__container container">
                    <div className="footer__left-side">
                        <ul className="footer__menu">
                            {this.renderItems()}
                        </ul>
                        <div className="copyright">2008-2021 © Склад-цех "Памятники в Чистеньком"</div>
                        <p>Работаем по всему Крыму.</p>
                    </div>

                    <div className="footer__right-side">

                        <ul className="footer__contacts contacts">
                            {this.renderContactItems()}

                        </ul>
                    </div>
                </div>

            </div>
        )
    }

}

export default Footer
