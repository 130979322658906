import React from 'react';
const parseSymbol =  (text) => {
    let re = /&[\\ a-z]{1,5};/g;
    return text.replace(re, " ");
}
const formatting = {

    parseText: (text) => {
        return text.split('|').map(function (item, key) {
            return (
                <div key={key} className="text">
                    {parseSymbol(item)}<br />
                </div>
            )
        })
    },

    formatDate: (date) => {
        let currentDatetime = new Date(date)
        let formattedDate = currentDatetime.getDate() + "." + (currentDatetime.getMonth() + 1) + "." + currentDatetime.getFullYear()
        return formattedDate;
    }
}

export default formatting;
