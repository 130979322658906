import React, {Component} from 'react';
import './MountingPage.scss';
import HomeContacts from '../HomeContacts/HomeContacts';
import {Helmet} from "react-helmet";
import {Link} from 'react-router-dom'
import Feedback from "../Feedback/Feedback";
import Button from "../Button/Button";
import ImageViewer from '../ImageViewer/ImageViewer'
import Communication from "../../Communication";
import ym from 'react-yandex-metrika';
import ReactGA from 'react-ga';
class MountingPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            openModal: false,
            productInfo: 'Уточнить стоимость установки'
        };
        this.handleClickOpen = this.handleClickOpen.bind(this);
        // this.handleChange = this.handleChange.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

   async componentDidMount() {
       window.scrollTo(0, 0);

       const  result = await Communication.fetchGallery();
       if (!result) {
           return
       }
       // console.log("result",result);
       const data =  result[1]?.images||[];
       this.setState({data: data});
    }
    handleClickOpen() {

        if(process.env.REACT_APP_GOOGLE==='active') {
            ym('reachGoal', 'zayavka_ustanovka');

            ReactGA.event({
                category: 'klik_po_knopke',
                action: 'ostavit_zayavku',
                label: 'ustanovka'
            });
        }
        this.setState({
            openModal: true
        })
    }

    handleClose() {
        this.setState({
            openModal: false
        })
    }
    renderItems() {
        const {data}  = this.state;
        if(data && data.length>0) {
            return data.map((item, index) => {

                return (
                    <div key={index} className="gallery__item">
                        <img
                            src={`/assets/photoGallery/${item}.jpg`} alt="gallery-item"
                            onClick={this.open.bind(this, index)}
                            className="home-gallery__img"/>
                    </div>
                )
            })
        }
    }
    open(index) {
        this.modal.open(index);
    }
    render() {
        const {data} = this.state;
        return (
            <>
                <Helmet>
                    <title>Установка памятников</title>
                    <meta name="description"
                          content='Установка памятников - от 3.000 руб. Осуществляем качественную установку памятников на всех кладбищах Крыма.'/>
                </Helmet>
                <div className='content'>
                    <div className="catalog">
                        <h1 className="top_title" >Установка памятников</h1>
                        <div className="opt__item">
                            <div className="opt__title"> Ритуальный склад-цех «Памятники в Чистеньком» занимается производством и установкой гранитных памятников в Крыму. Мастера компании изготовят надгробие по макетам из каталога или по индивидуальным параметрам заказчика. Установка памятника на могилу осуществляется по всему полуострову. В ассортименте –  надгробия из гранита для представителей любой религии, в том числе христианские и <Link to="/catalog/islam.html" className="catalog__link">мусульманские</Link>, и для других национальных диаспор Крыма - болгар, евреев, греков, армян, караимов и т.д.</div>
                            <h2 className="top_title" >Цена установки памятника на могилу</h2>
                            <div className="opt__subtitle">На сайте компании можно заказать установку памятника на могилу в Симферополе любой формы и сложности: одинарного и семейного, комплекса. Также у нас, в <Link to="/" className="catalog__link">склад-цех «Памятники в Чистеньком»</Link>  можно заказать 3d модель памятника и захоронения, в том числе ландшафтный дизайн с посадкой живых растений. Цена 3d модели памятника зависит от объема и сложности работы.  Цена установки памятника на могилу зависит от комплектации и сложности работы.</div>

                            <ul className="opt__ul">
                                <li>Срок изготовления: от одной недели до двух месяцев, в зависимости от сложности работы и выбранных материалов;</li>
                                <li>Монтажные работы: 1 неделя - 2 недели, зависит от загруженности и погодных условий. </li>
                            </ul>
                            <div className="opt__subtitle">Установка памятников в Симферополе производится по предоплате. На этапе заказа клиент вносит 30-50%, остальную сумму – после оказания услуги и после принятия работы. Выезд мастера для замеров и хранение памятника на складе – бесплатно. Тариф за доставку – 18 руб./км. Узнать, сколько стоит установка памятника, можно позвонив нам по телефону +7 (978) 789-04-52 или оставив заявку с помощью формы обратной связи. Выезд мастера бесплатный.</div>
                            <h3 className="top_title" >Как заказать установку надгробных памятников в Симферополе</h3>
                            <div className="opt__subtitle">Установка надгробных памятников начинается с оформления заявки на заказ. Сделайте это любым удобным способом: по телефону, заполнив форму на сайте или посетив склад-цех «Памятники в Чистеньком». Наш менеджер поможет определиться с дизайном, с оформлением памятника, рассчитает стоимость, также подскажет ориентировочную стоимость установки в данной комплектации, и проконсультирует о сроках. Чтобы сделать заказ, свидетельство о смерти и прочие документы не нужны. Только фото и данные (ФИО и даты). Для установки памятника на могилу в Крыму необходимо получить разрешение от администрации кладбища. Мы всегда стараемся оказать помощь в получении и решить этот вопрос самостоятельно.
                                <br/>
                                Если вы находитесь за пределами Крыма, возможна установка памятника в Крыму удаленно. При необходимости, мы пришлем вам фото и видеоотчет работ по благоустройству могилы.</div>
                            <h3 className="top_title" >Преимущества установки памятника на кладбище компанией «Памятники в Чистеньком»</h3>
                            <div className="opt__subtitle">Если вас интересует установка памятников и надгробий в Крыму – доверьтесь профессионалам. Мы уверены в качестве сервиса, поэтому предоставляем клиентам гарантию 25 лет на изделие и 5 лет на монтажные работы. Резка гранита и нанесение изображения – только вручную. Установка памятника на кладбище производится с учетом особенностей грунта. Поэтому перекос или проседание – исключены. </div>
                            <h3 className="top_title" >Свяжитесь с нами – будем рады сотрудничеству!</h3>
                            <div className="opt__subtitle"></div>
                        </div>
                    </div>

                    <div ><Button className="opt__button"
                                  type='callback'
                                  name="Уточнить стоимость установки памятника на могилу в Крыму"
                                  handleClick={this.handleClickOpen.bind(this)}
                    /></div>
                    <Feedback name="Уточнить стоимость установки" handleClose={this.handleClose} open={this.state.openModal}  selectedProduct={this.state.productInfo}/>
                    <HomeContacts/>
                    <h3 className="top_title" >Фото наших работ </h3>
                    <div className="gallery__container">
                        {this.renderItems()}
                    </div>
                    <ImageViewer
                        images={data}
                        showIndex
                        showPreview
                        ref={(component) => this.modal = component}
                        url='photoGallery/'
                    />
                </div>

            </>
        )
    }
}

export default MountingPage;
