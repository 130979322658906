import React, {Component} from 'react';
import './HomeMap.scss';
// import {Route, Switch} from "react-router";

class MapC extends Component {
    // https://yandex.ru/map-constructor/?um=constructor:d0d03ab6cdbd4983f787bae74088d68e3dddf2f9f24e1186dec01eb1b672ce38
    render() {
        return (
            <div id="third-party-container"  className="home-map" >

                <iframe
                    src="https://yandex.ru/map-widget/v1/?um=constructor%3Ad0d03ab6cdbd4983f787bae74088d68e3dddf2f9f24e1186dec01eb1b672ce38&amp;source=constructor"
                    width="100%" height="400" scroll="false" frameBorder="0"></iframe>

            </div>
        );
    }
}
class HomeMap extends Component {

    render() {

        return (
            <MapC />
        );
        /*
          <Switch>
                <Route exact path='/' render={() => <Map />}/>
            </Switch>
         */
    }
}

export default HomeMap;