import React, {Component} from 'react';
import './Banner.scss';
import Slider from '../Slider/Slider';
import Button from '../Button/Button';
import Feedback from '../Feedback/Feedback';
// import Communication from '../../Communication';
import ym from 'react-yandex-metrika';
import ReactGA from 'react-ga';
class Banner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            catalogData: [],
            catalogPage: true,
            // setOpen: false,
            openModal: false
        };
        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);


    }

    handleClickOpen() {
        if(process.env.REACT_APP_GOOGLE==='active') {
            ym('reachGoal', 'obratny_zvonok_left_banner');
            ReactGA.event({
                category: 'klik_po_knopke',
                action: 'obratny_zvonok',
                label: 'left_side_banner'
            });
        }
        this.setState({
            openModal: true
        })

    }

    handleClose() {
        this.setState({
            openModal: false
        })
    }


    componentDidMount() {
        // Communication.fetchCategoriesData().then((result) => {
        //     this.setState({ catalogData: result })
        // })
        //
        // if (window.location.pathname === '/') {
        //     this.setState({ catalogPage: false })
        // }
    }

    render() {

            return (

                <div className='banner'>
                    <Slider />
                    <div className="banner__wrapper">
                        <div className="banner__left">
                            <a href="https://pamyatniki-v-simferopole.ru" className="banner__logo">
                                <img className="banner__logo-image" src="/assets/logo.png" alt="logo" />
                            </a>
                            <div className="banner__phone">
                                <p className="banner__phone-title">Наш контактный номер</p>
                                <a className="banner__phone-link" href="tel:+79787890452">+7 (978) 789-04-52</a>
                            </div>

                            <Button  className="button__banner-callback"
                                     type='callback'
                                     name="Обратный звонок"
                                     handleClick={this.handleClickOpen.bind(this)}
                            />
                        </div>

                        <div className="banner__right">
                            <div className="banner__title">Склад‑цех
                                <div className="banner__text-small">"Памятники&nbsp;в&nbsp;чистеньком"</div>
                                <div className="banner__text-big">Работаем</div></div>

                            <div className="banner__text-subtitle">в Симферополе, Белогорске, Симферопольском и Белогорском районе и по всему Крыму.</div>
                            <div>Памятники из гранита</div>
                                <div>- Изготовим памятник любой формы и сложности</div>
                                <div>- Комплексное благоустройство захоронений</div>
                                <div>- Доставка и установка по Крыму</div>
                                <div>- Гарантия 25 лет</div>
                        </div>

                    </div>
                    <Feedback name="ОБРАТНЫЙ ЗВОНОК" handleClose={this.handleClose} open={this.state.openModal}/>
                </div>

            )
    }

}

export default Banner;
