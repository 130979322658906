import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import './Sidebar.scss';
import {connect} from 'react-redux';
import setCategoryAction from '../../actions/actionChangeCategory'
import setCurrentProduct from "../../actions/actionCurrentProduct";
import setCurrentArticle from "../../actions/actionCurrentArticle";

class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: ''
        };
        this.changeCategory = this.changeCategory.bind(this);
        this.handleClick = this.handleClick.bind(this);

    }

    UNSAFE_componentWillReceiveProps() {
        this.setState({
            menu: this.props.menu
        });

    }

    changeCategory(name) {
        this.props.setCatFunction(name);
        this.props.setCurrentProductFn(false) //обнуляю выбранный товар
        console.log('event click выбор категории');
    }

    handleClick() {
        this.props.setCatFunction(false); //обнуляю категорию
        this.props.setCurrentProductFn(false); //обнуляю выбранный товар
        this.props.setArticleFunction(false);
        console.log('event click переход на другую страницу')
    }


    renderSubCategories(item) {
        return item.subcategories.map((subItem, index) => {
            return (
                <NavLink key={index} className='sidebar__list-subitem'
                         to={`/catalog/${subItem.url}.html`}
                         onClick={this.changeCategory.bind(this, subItem.name)}
                >
                    {subItem.icon ? <img className='sidebar__subitem-icon' src={`/assets/${subItem.icon}`} alt="cat icon"/> : ''}
                    {subItem.name}</NavLink>
            )
        })
    }

    renderSidebarItems() {
        if (!this.state.menu) {
            return
        }
        return this.state.menu.map((item, index) => {

            if (item.url === '/') {
                return (
                    <li key={index}>
                        <NavLink exact
                                 className='sidebar__list-item'
                                 activeClassName='active'
                                 to={item.link}
                                 onClick={this.handleClick}>
                            {item.name}
                        </NavLink>

                    </li>
                )
            } else {
                return (
                    <li key={index}>
                        <NavLink
                            exact
                            className='sidebar__list-item'
                            activeClassName='active'
                            to={`${item.link}`}
                            onClick={this.handleClick}>
                            {item.name}
                        </NavLink>
                        {item.subcategories ? this.renderSubCategories(item) : ''}
                    </li>
                )
            }

        })


    }


    render() {
        return (
            <div className='sidebar container__sidebar'>
                <div className="sidebar__title">Меню</div>
                <ul className="sidebar__list">
                    {this.renderSidebarItems()}
                    <li key="ustanovka">
                        <NavLink exact
                                 className='sidebar__list-item'
                                 activeClassName='active'
                                 to="/ustanovka.html"
                                 onClick={this.handleClick}>
                            Установка 
                        </NavLink>

                    </li>
                </ul>

            </div>
        )
    }
}

function mapStateToProps(state) {

    return {
        menu: state.interafaceData.menu,
        category: state.interafaceData.category,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setCatFunction: (category) => {
            dispatch(setCategoryAction(category))
        },
        setCurrentProductFn: (product) => {
            dispatch(setCurrentProduct(product))
        },
        setArticleFunction: (article) => {
            dispatch(setCurrentArticle(article))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
