import React, {Component} from 'react';
import './OptPage.scss';
import HomeContacts from '../HomeContacts/HomeContacts';
import {Helmet} from "react-helmet";
import Feedback from "../Feedback/Feedback";
import Button from "../Button/Button";
import Communication from "../../Communication";
import ImageViewer from '../ImageViewer/ImageViewer'

import ym from 'react-yandex-metrika';
import ReactGA from 'react-ga';

class OptPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data:[],
            openModal: false,
            productInfo: 'ПАМЯТНИКИ ОПТОМ В КРЫМУ'
        };
        this.handleClickOpen = this.handleClickOpen.bind(this);
        // this.handleChange = this.handleChange.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }


    async componentDidMount() {
        window.scrollTo(0, 0);

        const  result = await Communication.fetchGallery();
        if (!result) {
            return
        }
        const data =  result[3]?.images||[];
        this.setState({data: data});
    }
    handleClickOpen() {
        if(process.env.REACT_APP_GOOGLE==='active') {
            ym('reachGoal', 'zayavka_optoviki');

             ReactGA.event({
                 category: 'klik_po_knopke',
                 action: 'ostavit_zayavku',
                 label: 'optovikam'
             });
        }
        this.setState({
            openModal: true
        })
    }

    handleClose() {
        this.setState({
            openModal: false
        })
    }
    renderItems() {
        if(this.state.data.length>0) {
            return this.state.data.map((item, index) => {

                return (
                    <div key={index} className="gallery__item">
                        <img
                            src={`/assets/photoGallery/${item}.jpg`} alt="gallery-item"
                            onClick={this.open.bind(this, index)}
                            className="home-gallery__img"/>
                    </div>
                )
            })
        }
    }
    open(index) {
        this.modal.open(index);
    }
    render() {
        const {data} = this.state;
        return (
            <>
                <Helmet>
                    <title>Памятники из гранита оптом</title>
                    <meta name="description"
                          content='Для оптовых покупателей действуют особые условия. Оставьте заявку на сайте или позвоните по телефону +7 (978) 789-04-52'/>
                </Helmet>
                <div className='content'>
                    <div className="catalog">
                        <h1 className="top_title" >Оптовикам</h1>
                        <div className="opt__item">
                            <div className="opt__title">Компания ритуальных услуг предлагает памятники оптом и в розницу по выгодной цене. Склад-цех «Памятники в Чистеньком» в Симферополе сотрудничает с мастерскими по всему Крыму. Мы реализуем гранитные памятники оптом, а также поставляем большие партии аксессуаров: вазы, оградки и прочее. Резка по камню и художественное оформление производятся вручную, а натуральный гранит доставляется напрямую из карьера, поэтому вы не будете сомневаться в качестве продукции.  </div>
                            <h2 className="top_title" >О продукции</h2>
                            <div className="opt__subtitle">В каталоге сайта –  надгробия для представителей любых религий, одинарные и для двоих, строгого и свободного дизайна. Памятники оптом в Крыму производятся гранита: Габбро, Масловка, Покост, Лезник, Васильевка и прочих. Мы предлагаем оптом изделия, представленные в <a href="/catalog.html" style={{"textDecoration":"underline"}}> каталоге на сайте</a>, а также изготавливаем надгробия по индивидуальным эскизам клиента. Цены на памятники оптом, как и сроки выполнения, согласовываются индивидуально. Возможно срочное изготовление стандартных моделей в случае наличия на складе камня. Такая услуга оплачивается дополнительно.
                               <br/> Помимо производства и <a href="/ustanovka.html" style={{"textDecoration":"underline"}}> установки надгробных памятников</a>, компания занимается художественным оформлением  могил. В нашей команде – талантливые и опытные мастера. Они выполнят гравировку и создадут портрет любой сложности. Любую услугу можно заказать как отдельно, так и в комплексе. На все памятники оптом в Крыму, а также монтажные работы предоставляется гарантия. <br/>Чтобы купить гранитные памятники оптом, свяжитесь с нами любым удобным способом. Мы принимаем заказы по телефону, через форму обратной связи на сайте, а также будем рады видеть вас в офисе. </div>
                            <h3 className="opt__title" >Памятники оптом от производителя – это:</h3>
                            <ul className="opt__ul">
                                <li>12 лет работы и сотни довольных клиентов;</li>
                                <li>25 лет гарантии на надгробия</li>
                                <li>5 лет гарантии на установку;</li>
                                <li>Резьба и художественное оформление любой сложности;</li>
                                <li>Бесплатное хранение памятников на складе;</li>
                                <li>Выполнение заказов удаленно (предоставляем фото и видеоотчет);</li>
                            </ul>
                        </div>
                    </div>
                    <div ><Button className="opt__button"
                                  type='callback'
                                  name="Оставить заявку ПАМЯТНИКИ ОПТОМ В КРЫМУ"
                                  handleClick={this.handleClickOpen.bind(this)}
                    /></div>
                    <div><Button className="opt__button"
                                 type='callback'
                                 name="Оставить заявку ПАМЯТНИКИ ОПТОМ В КРЫМУ"
                                 handleClick={this.handleClickOpen.bind(this)}
                    /></div>
                    <Feedback name="УСЛУГИ ДЛЯ МАСТЕРСКИХ" handleClose={this.handleClose} open={this.state.openModal} type="opt" selectedProduct={this.state.productInfo}/>
                </div>
                <HomeContacts/>
                <h3 className="top_title" >Фото наших работ </h3>
                <div className="gallery__container">
                    {this.renderItems()}
                </div>
                <ImageViewer
                    images={data}
                    showIndex
                    showPreview
                    ref={(component) => this.modal = component}
                    url='photoGallery/'
                />
            </>
        )
    }
}

export default OptPage;
