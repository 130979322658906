import React, {Component} from 'react';
import './Video.scss';
// import Communication from '../../Communication';

class Video extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
        };
    }

    componentDidMount() {
        // Communication.fetchCategoriesData().then((result) => {
        //     this.setState({ catalogData: result })
        // })
        //

        this.setState({
            data: [
                { src: 'https://www.youtube.com/embed/jm0NzRv5PPQ?rel=0', },
                { src: 'https://www.youtube.com/embed/u7QJ3XHTxa4?rel=0', },
            ]
        })
    }

    renderItems() {
        return this.state.data.map((item, index) => {
            return (
                <iframe className="video__item" src={item.src}
                        allowFullScreen="allowfullscreen" width="975"
                        height="549"
                        key={index}
                        title={'video'}
                />
            )
        })
    }

    render() {

        return (

            <div className="video">
                <div className="top_title">Видео</div>
                {this.renderItems()}
            </div>
        )
    }

}

export default Video
