import React from 'react';
import {fade, makeStyles} from '@material-ui/core/styles';
import {IconButton, AppBar, Toolbar, Typography} from '@material-ui/core';
import {Menu as MenuIcon} from '@material-ui/icons';
import Menu from './Menu';

const useStyles = makeStyles(theme => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: '#1a1d24'
    },

    toolbar: {
        width: '80%',
        margin: '0 auto',
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
            padding: '10px 0',
            width: '80%',
        },
        [theme.breakpoints.down('sm')]: {
            padding: '0',
            width: 'auto',
        },
        [theme.breakpoints.down('xs')]: {
            width: '90%',
        }

    },

    menuButton: {
        // marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },

    rightIcon: {
        marginLeft: 'auto',
        marginRight: theme.spacing(1),
    },

    title: {
        // marginLeft: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
        flex: 1
    },

    list: {
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        },
    },

    search: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    searchIcon: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },

    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 120,
            '&:focus': {
                width: 200,
            },
        },
    }

}));

const TitleBar = ({handleDrawerToggle}) => {
    const classes = useStyles();

    return (
        <AppBar position="fixed" className={classes.appBar}>
            <Toolbar className={classes.toolbar}>
                <div className={classes.list}>
                    <Menu/>
                </div>
                <Typography mr={0} ml="auto" color="primary" align="left" className={classes.title}>
                    Памятники в Чистеньком
                </Typography>
                <IconButton
                    color="primary"
                    aria-label="Open drawer"
                    edge="end"
                    onClick={handleDrawerToggle}
                    disabled={(handleDrawerToggle === null)}
                    className={classes.menuButton}
                >
                    <MenuIcon/>
                </IconButton>
            </Toolbar>
        </AppBar>
    )
};

export default TitleBar