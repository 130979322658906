import React, {Component} from 'react';
import './Calculator.scss';

import Button from "../Button/Button";
import {Table, TableBody, TableCell, TableRow, Radio, RadioGroup, FormControl, FormLabel, Checkbox, FormControlLabel, TextField, FormHelperText} from '@material-ui/core';


import Feedback from "../Feedback/Feedback";

class Calculator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentProduct: this.props.currentProduct,
            openModal: false,
            stella: '',
            stand: '',
            checkedImage: false,
            text: '',
            currentConfigurationIdx: 0,
            productInfo: ''

        };
        this.changeStateHandler = this.changeStateHandler.bind(this);
        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleRadioButtonChange = this.handleRadioButtonChange.bind(this);

    }
    UNSAFE_componentWillMount() {
        this.checkConfiguration();
    }

    changeStateHandler(e) {
        const {name, value} = e.target;

        this.setState({
            [name]: value
        })
    }

    handleClickOpen() {
        this.setState({
            openModal: true
        })
    }

    handleClose() {
        this.setState({
            openModal: false
        })
    }

    handleChange(e) {
        this.setState({
                checkedImage: !this.state.checkedImage
            }
        )
    }


    checkConfiguration(){
        const {currentProduct, currentConfigurationIdx} = this.state;

        let variant = currentProduct.variants[currentConfigurationIdx];
        if(!variant) {
            return ''
        }

        let info = `${currentProduct.name}, материал:  ${variant.material ? variant.material: ''}, 
        цена: ${variant.price}. Вариант - стелла: ${variant.stele}, тумба:${variant.pedestal}`
        info+=(variant.pallet)?`, плита:${variant.pallet}`:'';
        info+=(variant.flower)?`, цветник:${variant.flower}`:'';
        info+=(variant.vase)?`, ваза:${variant.vase}`:'';
        // цветник:${variant.flower},
        // ваза:${variant.vase}, `

        // console.log(info, '===========info')
        this.setState({
            productInfo:info
        })
    }

    //изображение
    renderImage() {
        return (<FormControlLabel control={<Checkbox checked={this.state.checkedImage} onChange={this.handleChange}/>} label="Нанесение портрета на памятник."/>)
    }

    //текст
    renderTextDecor() {
        return (<><TextField id="outlined-multiline-static" label="Введите текст на памятник" multiline rows="4" margin="normal" variant="outlined" name="text" onChange={this.changeStateHandler}         /><FormHelperText>Нанесение текста на памятник.</FormHelperText></>)
    }
    renderVariants(row) {
        return (<Table key={'index01'}><TableBody><TableRow className="configuration-item__row"><TableCell className="configuration-item__title"
                                   align="left">Стела:</TableCell><TableCell className="configuration-item__value"
                                   align="left">{row.stele}</TableCell></TableRow>{(row.pedestal && row.pedestal!=="") && <TableRow className="configuration-item__row"><TableCell className="configuration-item__title" align="left">Тумба:</TableCell><TableCell className="configuration-item__value" align="left">{row.pedestal}</TableCell></TableRow>}{(row.pallet && row.pallet!=="") && <TableRow className="configuration-item__row"><TableCell className="configuration-item__title"
                                   align="left">Плита:</TableCell><TableCell className="configuration-item__value"
                                   align="left">{row.pallet}</TableCell></TableRow>}{(row.flower && row.flower!=="") && <TableRow className="configuration-item__row"><TableCell className="configuration-item__title"
                                   align="left">Цветник:</TableCell><TableCell className="configuration-item__value"
                                   align="left">{row.flower}</TableCell></TableRow>}{(row.vase && row.vase!=="") && <TableRow className="configuration-item__row"><TableCell className="configuration-item__title"
                                   align="left">Ваза:</TableCell><TableCell className="configuration-item__value"
                                   align="left">{row.vase}</TableCell></TableRow>}</TableBody></Table>)
    }
    renderProductInfo() {
        const {currentProduct, currentConfigurationIdx} = this.state;

        const currentConfig = currentProduct.variants[currentConfigurationIdx];
        return (<Table key={'index2'}><TableBody><TableRow><TableCell className="configuration-info__title" align="left">Материал:</TableCell><TableCell className="configuration-info__value"
                                   align="left">{currentConfig ? currentConfig.material: ''}</TableCell></TableRow><TableRow><TableCell className="configuration-info__title" align="left">Форма:</TableCell><TableCell className="configuration-info__value"
                           align="left">{currentConfig ? currentConfig.forma?currentConfig.forma:'------': ''}</TableCell></TableRow><TableRow><TableCell className="configuration-info__title" align="left">Размер:</TableCell><TableCell className="configuration-info__value" align="left">{currentConfig ? currentConfig.stele : ''}</TableCell></TableRow></TableBody></Table>)
    }

    handleRadioButtonChange(event) {

        this.setState({
            currentConfigurationIdx: +event.target.value
        }, this.checkConfiguration)

    }

    renderConfiguration() {
        const {currentProduct, currentConfigurationIdx} = this.state;

        return (<FormControl component="fieldset"><FormLabel component="legend">Нажмите на нужную комплектацию</FormLabel><RadioGroup
                    value={String(currentConfigurationIdx)}
                    onChange={this.handleRadioButtonChange}
                >{currentProduct.variants.map((row, index) => {
                        return (<div key={index} className="calculator__configuration-item configuration-item"><FormControlLabel value={String(index)} control={<Radio/>} label={`${currentProduct.variants[index].price} руб.`}/>{this.renderVariants(row)}</div>)
                    })}</RadioGroup></FormControl>)
    }

    render() {
        const {currentProduct, currentConfigurationIdx} = this.state;
        let currentPrice;
        if (currentProduct.variants) {
            currentPrice = currentProduct.variants[currentConfigurationIdx]? currentProduct.variants[currentConfigurationIdx].price : '';
        }
        return (
            <div className="calculator">
                <div className="calculator__configuration-info configuration-info ">{this.renderProductInfo()}</div>
                <div className="calculator__explanation">Оставьте заявку, чтобы мы смогли сообщить Вам полную стоимость,
                    включающую цену УСТАНОВКИ.
                </div>
                <div className="calculator__order">
                    <div className="calculator__price">Цена {currentPrice} руб.</div>
                    <Button className="calculator__button"
                            type='callback'
                            name="Оставить заявку"
                            handleClick={this.handleClickOpen.bind(this)}
                    />
                </div>
                <Feedback name="ОСТАВИТЬ ЗАЯВКУ" handleClose={this.handleClose} open={this.state.openModal} selectedProduct={this.state.productInfo}/>
                <div className="calculator__form-container">
                    <h4 className="calculator__title">ВОЗМОЖНЫЕ КОМПЛЕКТАЦИИ</h4>{this.renderConfiguration()}{/*{this.renderImage()}{this.renderTextDecor()}*/}
                </div>
            </div>

        );
    }
}

export default Calculator;